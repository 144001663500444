//import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React, { useState, useRef, useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
import servername from "../constants";

export default function Login() {
  let navigate = useNavigate();
  const [currentTheme, setCurrentTheme] = useTheme();

  const [batch, setBatch] = useState('');

  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [D_Id, setD_Id] = useState('');
  const [Rollnumer, setRollnumer] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Passoutyear, setPassoutyear] = useState('');
  const [Linkdin, setLinkdin] = useState('');
  const [Education, setEducation] = useState('');
  const [Password, setPassword] = useState('');
  const [RePassword, setRePassword] = useState('');
  const [emailerror, setemailerror] = useState(false);
  const [iderror, setiderror] = useState(false);
  const [loadopen, setloadopen] = useState(false);
  const [alertopen, setalertopen] = useState(false);
  const [erroralertopen, seterroralertopen] = useState(false);
  const [NativePlace, setNativePlace] = useState('');
  const [WorkingPlace, setWorkingPlace] = useState('');
  const [passworderror, setpassworderror] = useState(false);
  const [loginerror, setloginerror] = useState(false);
  const [emailexisterror, setemailexisterror] = useState(false);
  const [idexisterror, setidexisterror] = useState(false);
  const [rollnumbererror, setrollnumbererror] = useState(false);
  const [passwordmatcherror, setpasswordmatcherror] = useState(false);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);
  const [adminlogin, setadminlogin] = useState(false);
  const [alumnilogin, setalumnilogin] = useState(false);
  const [loginmode, setloginmode] = useState(true);
  const [studentlogin, setstudentlogin] = useState(false);
  const [teacherlogin, setteacherlogin] = useState(false);
  const options = ['1987-1988', '1988-1989', '1989-1990', '1991-1992', '1992-1993', '1993-1994', '1994-1995', '1995-1996', '1996-1997', '1997-1998', '1998-1999', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2028-2029', '2029-2030',
  ];
  const defaultOption = 'Select';

  function submitform(type) {

    ////console.log('batch', batch);



    switch (type) {
      case 1: {
        if (Email == '') {
          setemailerror(true);
        }
        if (Password == '') {
          setpassworderror(true);
        }

        else {

          if (Email != '' && Password != '') {

            setloginerror(false);
            setemailexisterror(false);
            setpasswordmatcherror(false);
            setloadopen(true);
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ A_Email: Email, A_Password: Password })
            };
            fetch(`${servername}/alumni_login`, requestOptions)
              .then(response => response.json())
              .then((data) => {
                ////console.log(data);
                if (data.auth == 1) {
                  localStorage.setItem('login_key', data.token);
                  localStorage.setItem('email', data.email);
                  localStorage.setItem('p_image', data.profile);
                  localStorage.setItem('type', 'alumni');
                  navigate('/alumnus')
                } else if (data.auth == 0) {
                  setloginerror(true);
                  setemailexisterror(true);
                  setloadopen(false);
                } else {
                  setloginerror(true);
                  setpasswordmatcherror(true);
                  setloadopen(false);
                }

                //   if(data['document']['acknowledged']){
                //     setloadopen(false);
                //     setalertopen(true);

                //   }else{
                //     seterroralertopen(true);
                //   }
                //   ////console.log(data);

                //alert.show('Oh look, an alert!')
                //alert("successfully You have registerd");

              })
              .catch(error => {
                throw (error);
              });
            // ////console.log(Name);
          }
        }
      }
        break;
      case 2: {
        //console.log(Password)
        if (D_Id == '') {
          setiderror(true);
        }
        if (Password == '') {
          setpassworderror(true);
        }

        else {

          if (D_Id != '' && Password != '') {

            setloginerror(false);
            setidexisterror(false);
            setpasswordmatcherror(false);
            setloadopen(true);
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ D_Password: Password, D_Id: D_Id })
            };
            fetch(`${servername}/admin_login`, requestOptions)
              .then(response => response.json())
              .then((data) => {
                ////console.log(data);
                if (data.auth == 1) {
                  localStorage.setItem('login_key', data.token);
                  localStorage.setItem('type', 'admin');
                  // localStorage.setItem('p_image', data.profile);
                  navigate('/alumnus')
                } else if (data.auth == 0) {
                  setloginerror(true);
                  setidexisterror(true);
                  setloadopen(false);
                } else {
                  setloginerror(true);
                  setpasswordmatcherror(true);
                  setloadopen(false);
                }

                //   if(data['document']['acknowledged']){
                //     setloadopen(false);
                //     setalertopen(true);

                //   }else{
                //     seterroralertopen(true);
                //   }
                //   ////console.log(data);

                //alert.show('Oh look, an alert!')
                //alert("successfully You have registerd");

              })
              .catch(error => {
                throw (error);
              });
            // ////console.log(Name);
          }
        }
      }
        break;
      case 3: {
        if (Email == '') {
          setemailerror(true);
        }
        if (Password == '') {
          setpassworderror(true);
        }

        else {

          if (Email != '' && Password != '') {

            setloginerror(false);
            setemailexisterror(false);
            setpasswordmatcherror(false);
            setloadopen(true);
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ A_Email: Email, A_Password: Password })
            };
            fetch(`${servername}/alumni_login`, requestOptions)
              .then(response => response.json())
              .then((data) => {
                ////console.log(data);
                if (data.auth == 1) {
                  localStorage.setItem('login_key', data.token);
                  localStorage.setItem('email', data.email);
                  localStorage.setItem('p_image', data.profile);
                  localStorage.setItem('type', 'student');
                  navigate('/alumnus')
                } else if (data.auth == 0) {
                  setloginerror(true);
                  setemailexisterror(true);
                  setloadopen(false);
                } else {
                  setloginerror(true);
                  setpasswordmatcherror(true);
                  setloadopen(false);
                }

                //   if(data['document']['acknowledged']){
                //     setloadopen(false);
                //     setalertopen(true);

                //   }else{
                //     seterroralertopen(true);
                //   }
                //   ////console.log(data);

                //alert.show('Oh look, an alert!')
                //alert("successfully You have registerd");

              })
              .catch(error => {
                throw (error);
              });
            // ////console.log(Name);
          }
        }
      }

    }


  }


  function forgotpassword() {
    navigate('/email_verification')
  }

  const handleClickToOpen = () => {
    setalertopen(true);
  };

  const handleToClose = () => {
    setalertopen(false);
    seterroralertopen(false);
  }
  const handleToClose1 = () => {
    window.location.reload();
  }

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }

  const loginadmin = () => {
    setloginmode(false);
    setadminlogin(true);
  }
  const loginalumni = () => {
    setloginmode(false);
    setalumnilogin(true);
  }
  const loginstudent = () => {
    setloginmode(false);
    setstudentlogin(true);
  }

  const loginteacher = () => {
    setloginmode(false);
    setteacherlogin(true);
  }

  return (
    <React.Fragment>
      <Appbar
        currentTheme={currentTheme}
        setCurrentTheme={setCurrentTheme} />

      <main className='mainclass'>
        <center>
          {loginmode &&
            <div style={{ backgroundColor: 'rgb(252 252 252)', padding: '50px', borderRadius: '30px' }} className="MainDiv2"  >


              <div>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <h4 className='titlecss'>Alumni Association</h4>
                    <div className='row rowclass' >

                      <div className='col-md-6 btnmargin'>
                        <button className='btn btn-secondary btnwidth' onClick={loginadmin} style={{ backgroundColor: '#555592' }}>
                          <div className='row'>
                            <div className='col-4'>  <img className="loginim" src={require("./admin.png")}></img></div>
                            <div className='col-8'>  <Typography style={{ fontSize: '20px', top: '20%', position: 'relative' }}>Admin</Typography></div>
                          </div>
                        </button>
                      </div>

                      <div className='col-md-6 btnmargin'>
                        <button className='btn btn-secondary btnwidth' onClick={loginalumni} style={{ backgroundColor: '#166907' }}>
                          <div className='row'>
                            <div className='col-4'>   <img className="loginim" src={require("./alumni.png")}></img></div>
                            <div className='col-8'> <Typography style={{ fontSize: '20px', top: '20%', position: 'relative' }}>Alumni</Typography></div>
                          </div>
                        </button>
                      </div>

                      {/* <div className='col-md-6 btnmargin'>
                        <button className='btn btn-secondary btnwidth' onClick={loginstudent} style={{ backgroundColor: '#fa7317' }}>
                          <div className='row'>
                            <div className='col-4'>   <img className="loginim" src={require("./student.png")}></img></div>
                            <div className='col-8'> <Typography style={{ fontSize: '20px', top: '20%', position: 'relative' }}>Student</Typography></div>
                          </div>
                        </button>
                      </div> */}


                    </div>
                    <br></br>
                    {/* <div className='row rowclass' >
                      
                      <div className='col-md-6 btnmargin'>
                        <button className='btn btn-secondary btnwidth' onClick={loginstudent} style={{ backgroundColor: '#fa7317' }}>
                          <div className='row'>
                          <div className='col-4'>   <img className="loginim" src={require("./student.png")}></img></div>
                          <div className='col-8'> <Typography style={{ fontSize: '20px', top: '20%', position: 'relative' }}>Student</Typography></div>
                        </div>
                        </button>
                      </div>

                      <div className='col-md-6 btnmargin'>
                        <button className='btn btn-secondary btnwidth' onClick={loginteacher} >
                          <div className='row'>
                            <div className='col-4'>   <img className="loginim" src={require("./student.png")}></img></div>
                            <div className='col-8'> <Typography style={{ fontSize: '20px', top: '20%', position: 'relative' }}>Teacher</Typography></div>
                          </div>
                        </button>
                      </div>
                      </div> */}



                  </Grid>
                </Grid>
              </div>
            </div>
          }
          {!loginmode &&
            <div style={{ backgroundColor: 'rgb(252 252 252)', padding: '50px', borderRadius: '30px' }} className="MainDiv1"  >


              <div>
                <Dialog open={alertopen} onClose={handleToClose}>
                  <DialogTitle style={{ color: 'green' }}>Success</DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ color: 'black' }}>
                      You Have Registered successfully
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleToClose1}
                      color="primary" autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>


                <Dialog open={erroralertopen} onClose={handleToClose}>
                  <DialogTitle style={{ color: 'red' }}>Alert!!</DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ color: 'black' }}>
                      Alumni Name Already Exists
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleToClose}
                      color="primary" autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

                {loadopen && <div className="loader1" ></div>}
                {adminlogin &&
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography style={{ fontSize: '24px' }}>Admin Login</Typography>
                      {/* <Typography style={{fontSize: '22px', marginTop: '10px'}}>Login</Typography> */}

                      {loginerror && <Typography style={{ color: 'red', textAlign: 'center' }}>  Login Failed!!</Typography>}

                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {/* <i className="fa fa-envelope"></i> */}
                      <TextField

                        required
                        id="D_id"
                        name="D_id"
                        label="Admin Id"
                        fullWidth
                        autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setD_Id(value.target.value); setiderror(false) }}
                      />

                      {idexisterror && <Typography style={{ color: 'red', textAlign: 'left' }}>Admin Id Doesn't Exist</Typography>}
                      {iderror && <Typography style={{ color: 'red', textAlign: 'left' }}> Admin Id Can't be blank!!!</Typography>}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField

                        required
                        type='password'
                        id="Password"
                        name="Password"
                        label="Password"
                        fullWidth
                        autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setPassword(value.target.value); setpassworderror(false); }}
                      />
                      {/* <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
                      {passwordmatcherror && <Typography style={{ color: 'red', textAlign: 'left' }}>Invalid password!!!</Typography>}
                      {passworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Can't be blank!!!</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography onClick={forgotpassword} style={{ cursor: 'pointer' }}>Forgot Password</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <button className="btn btn-primary" style={{
                        width: '230px',
                        marginTop: '20px'
                      }} onClick={() => submitform(2)}><Typography>Login</Typography></button>
                    </Grid>

                  </Grid>
                }


                {/* {loadopen && <div className="loader1" ></div>  } */}
                {alumnilogin &&
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography style={{ fontSize: '24px' }}>Alumni Login</Typography>
                      {/* <Typography style={{fontSize: '22px', marginTop: '10px'}}>Login</Typography> */}

                      {loginerror && <Typography style={{ color: 'red', textAlign: 'center' }}>  Login Failed!!</Typography>}

                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {/* <i className="fa fa-envelope"></i> */}
                      <TextField

                        required
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setEmail(value.target.value); setemailerror(false) }}
                      />

                      {emailexisterror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Doesn't Exist</Typography>}
                      {emailerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Can't be blank!!!</Typography>}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField

                        required
                        type='password'
                        id="Password"
                        name="Password"
                        label="Password"
                        fullWidth
                        // autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setPassword(value.target.value); setpassworderror(false); }}
                      />
                      {/* <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
                      {passwordmatcherror && <Typography style={{ color: 'red', textAlign: 'left' }}>Invalid password!!!</Typography>}
                      {passworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Can't be blank!!!</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography onClick={forgotpassword} style={{ cursor: 'pointer' }}>Forgot Password</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <button className="btn btn-primary" style={{
                        width: '230px',
                        marginTop: '20px'
                      }} onClick={() => submitform(1)}><Typography>Login</Typography></button>
                    </Grid>

                  </Grid>
                }
                {studentlogin &&
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography style={{ fontSize: '24px' }}>Student Login</Typography>
                      {/* <Typography style={{fontSize: '22px', marginTop: '10px'}}>Login</Typography> */}

                      {loginerror && <Typography style={{ color: 'red', textAlign: 'center' }}>  Login Failed!!</Typography>}

                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {/* <i className="fa fa-envelope"></i> */}
                      <TextField

                        required
                        id="rollnumber"
                        name="rollnumber"
                        label="Rollnumber"
                        fullWidth
                        autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setEmail(value.target.value); setemailerror(false) }}
                      />

                      {emailexisterror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Doesn't Exist</Typography>}
                      {emailerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Can't be blank!!!</Typography>}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField

                        required
                        type='password'
                        id="Password"
                        name="Password"
                        label="Password"
                        fullWidth
                        // autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setPassword(value.target.value); setpassworderror(false); }}
                      />
                      {/* <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
                      {passwordmatcherror && <Typography style={{ color: 'red', textAlign: 'left' }}>Invalid password!!!</Typography>}
                      {passworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Can't be blank!!!</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography onClick={forgotpassword} style={{ cursor: 'pointer' }}>Forgot Password</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <button className="btn btn-primary" style={{
                        width: '230px',
                        marginTop: '20px'
                      }} onClick={() => submitform(3)}><Typography>Login</Typography></button>
                    </Grid>

                  </Grid>
                }

                  {teacherlogin &&
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography style={{ fontSize: '24px' }}>Teacher Login</Typography>
                      {/* <Typography style={{fontSize: '22px', marginTop: '10px'}}>Login</Typography> */}

                      {loginerror && <Typography style={{ color: 'red', textAlign: 'center' }}>  Login Failed!!</Typography>}

                    </Grid>

                    <Grid item xs={12} sm={12}>
                      {/* <i className="fa fa-envelope"></i> */}
                      <TextField

                        required
                        id="TeacherID"
                        name="TeacherID"
                        label="Teacher ID"
                        fullWidth
                        // autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setEmail(value.target.value); setemailerror(false) }}
                      />

                      {emailexisterror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Doesn't Exist</Typography>}
                      {emailerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Can't be blank!!!</Typography>}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField

                        required
                        type='password'
                        id="Password"
                        name="Password"
                        label="Password"
                        fullWidth
                        // autoComplete='new-password'
                        // autoComplete="given-name"
                        variant="standard"
                        onBlur={(value) => { setPassword(value.target.value); setpassworderror(false); }}
                      />
                      {/* <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
                      {passwordmatcherror && <Typography style={{ color: 'red', textAlign: 'left' }}>Invalid password!!!</Typography>}
                      {passworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Can't be blank!!!</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography onClick={forgotpassword} style={{ cursor: 'pointer' }}>Forgot Password</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <button className="btn btn-primary" style={{
                        width: '230px',
                        marginTop: '20px'
                      }} onClick={() => submitform(3)}><Typography>Login</Typography></button>
                    </Grid>

                  </Grid>
                }
              </div>

            </div>
          }
        </center>








      </main>

    </React.Fragment>
  );
}