import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map from "./scenes/geography/alumnilocations";
import AlertMessage from "./components/Alertmsgs";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Appbar from "../appbar";
import { useTheme } from "../theme";
import servername from "../constants";
import Topbar from "./scenes/global/Topbar";


const LocationAnalytics = () => {
    const [loadOpen, setLoadOpen] = useState(true);
  
    const [theme, colorMode] = useMode();
    const [alumniLocationData, setAlumniData] = useState([]);

    const [currentTheme, setCurrentTheme] = useTheme();
  
    let navigate = useNavigate();
    useEffect(() => {
        //////console.log(servername);
        if (!localStorage.getItem('login_key')) {
            // user is not authenticated
            navigate('/login');
        }

    });

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);

    function alumnidatalist() {

       
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ key: "" }),
        };
        fetch(`${servername}/alumni_list`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
    
            setLoadOpen(false);
          
            setAlumniData(data)
            console.log(data);
          
    
          });
    
      }

      useEffect(() => {
        alumnidatalist();
      }, []);

    return (

        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    {/* <Sidebar isSidebar={isSidebar} /> */}
                    <main className="content">
                        <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
                        <Topbar setIsSidebar={''} />
                        <Box  m="20px">
                            {loadOpen && <div className="loader dataerror" style={{ marginTop: '2%' }}></div>}

                            <AlertMessage showModal={showModal} handleClose={handleClose} />
                            <Box >
                                {/* <Box display="flex"  alignItems="center">
                                    <Typography
                                        variant="h3"
                                        fontWeight="600"
                                    >
                                        Alumnus Location Wise Count
                                    </Typography>
                                </Box> */}
                                <Map  alumniData={alumniLocationData} />


                            </Box>

                        </Box>

                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>


    );
};

export default LocationAnalytics;
