import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React, { useState, useRef, useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
import servername from "../constants";
import Compress from "react-image-file-resizer";
export default function Gallery() {
  let navigate = useNavigate();
  const [currentTheme, setCurrentTheme] = useTheme();
  const [imagezie, setimagezie] = useState(false);
  const [imageload, setimageload] = useState(false);
  const [profileimage, setprofileimage] = useState();
  const [erroralertopen, seterroralertopen] = useState(false);
  const [galleryimages, setgalleryimages] = useState([]);
  const [linkerroralertopen, setlinkerroralertopen] = useState(false);
  const [link, setlink] = useState("");
  const [videoLinks, setVideoLinks] = useState([]);


  useEffect(() => {
    //////console.log(servername);
    if (!localStorage.getItem('login_key')) {
      // user is not authenticated
      navigate('/login');
    }
    // getimages();
  });

  function readlinks() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ key: 'key' })
    };
    fetch(`${servername}/youtube_links`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        setVideoLinks(data)


      })
      .catch(error => {
        throw (error);
      });
  }

  useEffect(() => {
    // Make an API request to fetch video links
    readlinks();
  }, []);
  useEffect(() => {
    getimages();
  }, []);






  let files;
  function getimages() {

    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },

      };
      fetch(`${servername}/gallery`, requestOptions)
        .then(response => response)
        .then((data) => {
          console.log(data)
          // if(data){
          // ////console.log(data);
          setgalleryimages(data);
          // }


        })
        .catch(error => {
          throw (error);
        });
      // files is now an array of file names, do what you want with that (create <img> tags, etc.)
    } catch (err) {
      //console.error(err)
    }
  }



  function upload() {
    if (imageload) {
      var filename = profileimage.name;
      //////console.log(profileimage);
      var reader = new FileReader();

      reader.onload = function (e) {
        Compress.imageFileResizer(
          profileimage, // the file from input
          200, // width
          200, // height
          "JPEG", // compress format WEBP, JPEG, PNG
          100, // quality
          0, // rotation
          (uri) => {
            //////console.log(uri);
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ url: uri, filename: filename })
            };
            fetch(`${servername}/profile_image_upload_gallery`, requestOptions)
              .then(response => response.json())
              .then((data) => {
                //console.log(data);
                if (data) {
                  // window.location.reload();
                }


              })
              .catch(error => {
                throw (error);
              });
            // You upload logic goes here
          },
          "base64" // blob or base64 default base64
        );
        // //////console.log(e.target.result);

        //  navigator.clipboard.writeText(e.target.result);
      }

      reader.readAsDataURL(profileimage);




    } else {
      seterroralertopen(true);
    }
  }

  function addLink() {
    console.log(link)


    if (link != '') {
      if (link.split('youtu.be/')[1] != '' && link.split('youtu.be/')[1] != null) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ link: link.split('youtu.be/')[1] })
        };
        fetch(`${servername}/youtube_link`, requestOptions)
          .then(response => response.json())
          .then((data) => {
            //console.log(data);
            if (data) {
              // console.log(data)
              alert(data['result']);
              readlinks();
            } else {
              alert('Error!!')
            }


          })
          .catch(error => {
            throw (error);
          });
      } else {
        alert('Invalid Link')
      }

    } else {
      setlinkerroralertopen(true);
    }

  }


  const handleToClose = () => {

    seterroralertopen(false);
  }
  function imagesize(p_image) {
    //////console.log(p_image.size);
    if (p_image.size > 50000000) {
      setimagezie(true);
    } else {
      setimagezie(false);
    }
  }
  const YouTubeEmbed = ({ embedCode }) => {
    // alert('calling')
    return (
      <div className="video-responsive" style={{ margin: 10 }}>
        <iframe
          width="448"
          height="258"
          src={`https://www.youtube.com/embed/${embedCode}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };


  return (
    <React.Fragment>
      <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />

      <main className="mainclass" >

        <Dialog open={erroralertopen} onClose={handleToClose}>
          <DialogTitle style={{ color: 'red' }}>Alert!!</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: 'black' }}>
              Please Select The Image!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToClose}
              color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {localStorage.getItem('type') == 'admin' &&
          <Grid container spacing={3} style={{ 'marginBottom': '20px' }}>
            <Grid item xs={12} sm={4}>
              <div className="input-group mb-3" >
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}><Typography>YouTube Link</Typography></span>
                </div>
                <input accept="image/*" type="text" className="form-control" style={{ paddingTop: '4px', paddingLeft: '5px' }} placeholder="Enter YouTube Link" id="profileimage" aria-label="Username" aria-describedby="basic-addon1" onChange={(value) => {
                  setlink(value.target.value)
                }} />
              </div>
              {linkerroralertopen && <Typography style={{ color: 'red', textAlign: 'left' }}>Link Can't be Empty!!</Typography>}
            </Grid>
            <Grid item xs={12} sm={2}>
              <button className="btn btn-primary" style={{
                width: '150px',
              }} onClick={addLink}><Typography>Add</Typography></button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <div className="input-group mb-3" >
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1" style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}><Typography>Gallery</Typography></span>
                    </div>
                    <input accept="image/*" type="file" className="form-control" style={{ paddingTop: '4px', paddingLeft: '5px' }} placeholder="profileimage" id="profileimage" aria-label="Username" aria-describedby="basic-addon1" onChange={(value) => { setprofileimage(value.target.files[0]); imagesize(value.target.files[0]); setimageload(true); }} />
                  </div>
                  {imagezie && <Typography style={{ color: 'red', textAlign: 'left' }}>Image Size Shoudn't be morethen 50kb!!</Typography>}
                  {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <button className="btn btn-primary" style={{
                    width: '150px',
                  }} onClick={upload}><Typography>Upload</Typography></button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="row">
          {galleryimages.length > 0 &&

            galleryimages.map(element => (

              <div className="col-md-3 ">
                <div className="cards c_hover">
                  <img className="img_class" src={element} />

                  {/* <img className="img_class" src={servername + "/public/gallery/"+element}/> */}
                </div>
              </div>
            ))
          }
          {/* <div className="col-md-3 ">
                  <div className="cards">
                    </div>
            </div>         
            <div className="col-md-3 ">
                  <div className="cards">
                    </div>
            </div>   */}
          {videoLinks.length > 0 && videoLinks.map((link, index) => (
            <YouTubeEmbed key={index} embedCode={link.link} />
          ))}
        </div>
      </main>
    </React.Fragment>
  );

}