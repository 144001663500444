import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import { VerifiedUserRoundedIcon } from "@mui/icons-material";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import servername from "../../../constants";
import { useTheme as localtheme } from "../../../theme";
import AlumniTable from "../table/alumnitable";
import Map from "../geography/alumnilocations";
import PieChartGraph from "../../components/PieChart";
import { CChart } from '@coreui/react-chartjs'
import AlertMessage from "../../components/Alertmsgs";



const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentTheme, setCurrentTheme] = localtheme();
  const [alumnidata, setalumnidata] = useState([]);
  const [totalcount, settotalcount] = useState(0);
  const [alumni_count, setalumni_count] = useState([]);
  const [batches, setbatches] = useState([]);

  const [loadOpen, setLoadOpen] = useState(true);
  const [alumniTableData, setAlumniData] = useState([]);

  const [BarAlumniData, SetBarAlumnidata] = useState([]); // State to hold alumni data


  let navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('login_key')) {
      navigate('/login');
    }

  });

  const columns = [
    {
      Header: 'Name',
      accessor: 'A_Name',
    },
    {
      Header: 'Batch',
      accessor: 'A_Batch',
    },
    // {
    //   Header: 'District',
    //   accessor: 'A_District',
    // },
    {
      Header: 'Email',
      accessor: 'A_Email',
    },
    {
      Header: 'Occupation',
      accessor: 'A_Occupation',
    },
    {
      Header: 'Passout Year',
      accessor: 'A_Passoutyear',
    },
    {
      Header: 'Working Place',
      accessor: 'A_WorkingPlace',
    },
    {
      Header: 'Education',
      accessor: 'A_Education',
    }
  ];
  // var alumnidata = [];
  function alumnidatalist() {

    let copyOfAluminData = [...alumnidata];
    const alumnicount = [];
    const batcheslist = [];
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: "" }),
    };
    fetch(`${servername}/alumni_list`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {


        setLoadOpen(false);
        settotalcount(data.length);
        setAlumniData(data)
        console.log(data);
        SetBarAlumnidata(data)

        data.forEach(item => {
          if (!batcheslist.includes(item.A_Batch)) {
            batcheslist.push(item.A_Batch);
            const count = data.filter(alumnus => alumnus.A_Batch === item.A_Batch).length;
            alumnicount.push(count);
            copyOfAluminData.push({ 'Batch': item.A_Batch, 'count': count });
          }
        });


        //console.log(copyOfAluminData);
        setalumnidata(copyOfAluminData);
        console.log("Count of alumni:", alumnicount)
        console.log("BatchList:", batcheslist)

        setalumni_count(alumnicount);
        setbatches(batcheslist);

      });

  }

  useEffect(() => {
    alumnidatalist();
 
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleFunctionCall = () => {
    handleShow();
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadOpen(false);
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timeout);
  }, []);
  return (
    <Box m="20px">
      {loadOpen && <div className="loader dataerror" style={{ marginTop: '3%' }}></div>}

      <AlertMessage showModal={showModal} handleClose={handleClose} />
      {/* HEADER */}

      <Box display="flex" justifyContent="space-between" alignItems="center" gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        md: 'repeat(12, 1fr)'
      }}>
        <Header title="DASHBOARD" subtitle="Welcome To Alumni Analytics Dashboard" />
        {/* <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box> */}
      </Box>

      {!loadOpen && (

        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"

        >
          <Box
            onClick={handleFunctionCall}
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ background: 'radial-gradient(#1fe4f5, #3fbafe)'  ,cursor:'pointer'}}
            borderRadius={'10px'}

          >
            <StatBox
            
              title={totalcount}
              subtitle="Total Number Of Alumnus"
              icon={<i className="fa fa-users fa-lg" style={{ color: 'black', fontSize: '35px'}} aria-hidden="true"></i>}
            />
          </Box>
          <Box
            onClick={handleFunctionCall}
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
            style={{ background: 'radial-gradient(#fbc1cc, #fa99b2)'  ,cursor:'pointer'}}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={'10px'}
          >
            <StatBox

              title="85"
              subtitle="No. Of Males"
              // progress="0.50"
              // increase="+21%"
              icon={
                <i className="fa fa-users fa-male" style={{ color: 'black', fontSize: "35px" }} aria-hidden="true"></i>
              }
            />
          </Box>
          <Box
            onClick={handleFunctionCall}
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
            style={{ background: 'radial-gradient(#60efbc, #58d5c9)'  ,cursor:'pointer'}}
            borderRadius={'10px'}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="55"
              subtitle="No. Of Females"
              // progress="0.30"
              // increase="+5%"
              icon={
                <i class="fa fa-users fa-female" style={{ color: 'black', fontSize: "35px" }} aria-hidden="true"></i>
              }
            />
          </Box>
          <Box
            onClick={handleFunctionCall}
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
            style={{ background: 'radial-gradient(#76b2fe, #b69efe)'  ,cursor:'pointer'}}
            borderRadius={'10px'}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="7"
              subtitle="Events"
              // progress="0.80"
              // increase="+43%"
              icon={
                <i className="fa fa-th  fa-lg" style={{ color: 'black', fontSize: "35px" }} aria-hidden="true"></i>
              }
            />
          </Box>


          <Box
            gridColumn={{ xs: 'span 12', md: 'span 8' }}
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>

                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  Total Count : {totalcount}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Year Wise Alumnus Graph
                </Typography>
              </Box>
              <Box>
                {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton> */}
              </Box>
            </Box>
            <Box height="250px" m="-20px 0 0 0">
              <LineChart  style={{
     
     cursor: 'pointer'
   }} data={{ batches, alumni_count }} finaldata={BarAlumniData} isDashboard={true} />
            </Box>
          </Box>
          <Box
            gridColumn={{ xs: 'span 12', md: 'span 4' }}
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
            >
              <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                Number Of Alumnus Year Wise
              </Typography>
              <br></br>
              <Typography
                // color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                Count
              </Typography>
            </Box>

            {alumnidata.map((person, i) => (
              <Box
                // key={`${transaction.from}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >

                <Box display="flex" alignItems="center">
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {person.Batch}
                  </Typography>
                </Box>

                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  {person.count}
                </Box>
              </Box>
            ))}
          </Box>

          {/* <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Alumnus Category
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="25px"
            >
              <PieChartGraph />
              <ProgressCircle size="125" />
            <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
              >
                $48,352 revenue generated
              </Typography> 
              <Typography>Includes extra misc expenditures and costs</Typography>
            </Box>
          </Box> */}
          {/* <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Bar Graph
            </Typography>
            <Box height="250px" mt="-20px">
            <BarChart
  data={batches.map((batch, index) => ({
    batch,
    count: alumni_count[index],
  }))}
/>
            </Box>
          </Box> */}




          <Box
            gridColumn={{ xs: 'span 12', md: 'span 12' }}
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            p="10px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="-50px"
            >
              <Typography
                variant="h5"
                fontWeight="bold"
              // color={colors.greenAccent[500]}
              >
                Batch Wise Representation Of Alumnus
              </Typography>

            </Box>
            <br>
            </br>
            <BarChart

              data={BarAlumniData}
            // data={batches.map((batch, index) => ({
            //   batch,
            //   count: alumni_count[index],
            // }))}
            />

          </Box>

















          {/* <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Geography Based Traffic
            </Typography>

            <Box height="200px">
              <GeographyChart isDashboard={true} />
            </Box>
          </Box> */}

          <Box
            gridColumn={{ xs: 'span 12', md: 'span 12' }}
            gridRow="span 11"
            p="10px"
            backgroundColor={colors.primary[400]}
          >
            <AlumniTable columns={columns} data={alumniTableData} />
          </Box>

        </Box>


      )}
      
      {/* <Box p="20px">
      <Box display="flex" p="5px" alignItems="center">
                  <Typography
                    variant="h3"
                    fontWeight="600"
                  >
                    Alumnus Location Wise Count
                  </Typography>
                </Box>
                <Map alumniData={alumniTableData} />
               

     </Box> */}
    
    </Box>


  );
};

export default Dashboard;
