import React from "react";
// import AppBarAndDrawer from "./AppBarAndDrawer/AppBarAndDrawer";
// import { BrowserRouter as Router, Routes , Route,Redirect } from "react-router-dom";
import { BrowserRouter, Routes, Route,Navigate  } from "react-router-dom";
// import { SignIn } from "./SignIn";
// import { Dashboard } from "./Dashboard/Dashboard";
import  Home  from "./Home/Home";

import { ThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "./theme";
import { DataProvider } from "./Providers/DataProvider";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { configureStore } from "@reduxjs/toolkit";
import peopleReducer from "./ReduxTable/peopleSlice";
import { Provider } from "react-redux";
import Register from "./Register/register";
import Alumnus from "./Alumni/alumnus";
import ResetPassword from "./ResetPassword/resetpassword";
import Email_verify from "./ResetPassword/email_verify";
import Login from "./Login/login";
import Profile from "./profile/profile";
import Gallery from "./Gallery/gallery";
import Event from "./Events/event";
import Eventsetting from "./Events/eventsetting";
import Analytics from "./Analytics/analytics";
import Alumni_Verification from "./AlumniVerification/alumniverification";
import Request from "./Alumnirequest/Request";
import LocationAnalytics from "./Analytics/location_anaytics";
import Feedback from "./Feedback/Feedback";
import Donation from "./Donation/Donate";
import MoreDetails from "./Alumni/moredetails";
import Jobsettings from "./Jobs/jobsettings";
//import { Switch,  } from "react-router-dom";

export default function App() {
  
  const store = configureStore({
    reducer: {
      people: peopleReducer,
    },
  });
const  r_page =[
    {
      path : '/home',
      component : <Home/>
     },
     {
      path : '/register',
      component : <Register/>
     },
     {
      path : '/alumnus',
      component : <Alumnus/>
     },
     {
      path : '/gallery',
      component : <Gallery/>
     },
     {
      path : '/login',
      component : <Login/>
     },
     {
      path : '/resetpassword',
      component : <ResetPassword/>
     },
     {
      path : '/email_verification',
      component : <Email_verify/>
     },
     {
      path : '/profile',
      component : <Profile/>
     },
     {
      path : '/events',
      component : <Event/>
     },
     {
      path : '/eventsetting',
      component : <Eventsetting/>
     },
  {
    path: '/jobssettings',
    component: <Jobsettings />
  },
     {
      path : '/analytics',
      component : <Analytics/>
     },
     {
      path : '/feedback',
      component : <Feedback/>
     },
     {
      path : '/donate',
      component : <Donation/>
     },
     {
      path : '/locations',
      component : <LocationAnalytics/>
     },
     {
      path : '/alumni_verification',
      component : <Alumni_Verification/>
     },
     {
      path : '/request',
      component : <Request/>
     },
  {
    path: '/moredetails',
    component: <MoreDetails />
  }
    ]
  const [currentTheme, setCurrentTheme] = useTheme();
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={currentTheme}>
          <Provider store={store}>
            <DataProvider>

            {/* <Router>
        <Routes  >  
          <Route path='/' exact component={Home}/>            
          {r_page.map((item , i)=>
           <Route key={i} exact {...item} /> )}
          </Routes >        
      </Router>  */}

      <BrowserRouter>
      <Routes>
      <Route path='/' exact element={<Home/>}/>            
          {r_page.map((item , i)=>
          (
         ///   item['path'] == '/login' ? <Route key={i} path={item['path']} element={item['component']} />:

            
          //  localStorage.getItem('login_key')?
            <Route key={i} path={item['path']} element={item['component']} />  
      
          )
           )}

           
      </Routes>
    </BrowserRouter>
      </DataProvider>
          </Provider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
);


              {/* <Router>
             
                <div> */
                  /* <AppBarAndDrawer
                    currentTheme={currentTheme}
                    setCurrentTheme={setCurrentTheme}
                  /> */
                  /* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */
                  /* <Switch> */
                    /* <Route path="/home">
                      <Home />
                    </Route>
                    <Route path="/profile">
                      <Driver id={1} />
                    </Route>
                    <Route path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route path="/history">
                      <History />
                    </Route>
                    <Route exact path="/people">
                      <People />
                    </Route>
                    <Route path={`/people/:driverId`}>
                      <Driver />
                    </Route>
                    <Route path="/map">
                      <Trips />
                    </Route>
                    <Route path="/components">
                      <Components />
                    </Route>
                    <Route path="/settings">
                      <Settings
                        currentTheme={currentTheme}
                        setCurrentTheme={setCurrentTheme}
                      />
                    </Route> */
                      
                /* <Route path="/home" element={localStorage.getItem('token') ? 
                   <Home /> : <SignIn/>} />
                  <Route path="/history" element={
                    localStorage.getItem('token') ? 
                  <History /> : <SignIn/>} />
               
                    <Route path="/">
                      <SignIn />
                    </Route>
                  </Switch>
                </div>
               
                  </Router> */}
              
            
  
}
