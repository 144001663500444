// import React, { useState } from 'react';
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
//   TextField,
// } from '@mui/material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUsers } from '@fortawesome/free-solid-svg-icons';

// function AlumniTable({ columns, data }) {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [searchTerm, setSearchTerm] = useState('');

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const filteredData = data.filter((row) =>
//     String(row.A_Name).toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//       <Box>
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="15px"
//           padding="15px"
//         >
//            <Typography variant="h3" fontWeight="600" sx={{ display: 'flex', alignItems: 'center' }}>
//            <FontAwesomeIcon icon={faUsers} style={{ color: 'black', fontSize: '35px', marginLeft: '10px' }} />
//            &nbsp;
//             Alumni Data
//           </Typography>
//           <TextField
//             label="Search Name"
//             variant="outlined"
//             size="small"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             sx={{
//               ml: { xs: 'auto', sm: '10px' },
//               width: { xs: '100%', sm: 'auto' },
//               mt: '10px',
//               mr: '10px',
//             }}
//           />
//         </Box>

//         <TableContainer sx={{ maxHeight: '550px' }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead sx={{ background: 'radial-gradient(rgb(31, 228, 245), rgb(63, 186, 254))' }}>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.Header || column.id}
//                     align="left"
//                     style={{
//                       minWidth: column.minWidth || 150,
//                       fontWeight: 'bold',
//                       fontSize: '0.8rem',
//                     }}
//                   >
//                     {column.Header || column.label}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {filteredData
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row, index) => (
//                   <TableRow hover role="checkbox" tabIndex={-1} key={index}>
//                     {columns.map((column) => (
//                       <TableCell key={column.accessor || column.id} align="left">
//                         {row[column.accessor || column.id]}
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <TablePagination
//           rowsPerPageOptions={[10, 25, 100]}
//           component="div"
//           count={filteredData.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Box>
//     </Paper>
//   );
// }

// export default AlumniTable;


import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from 'react';
import { Button, Modal, Input } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useMediaQuery } from '@mui/material';
import exportAlumniDataToExcel from "./downloaddata";
import React from "react";

function AlumniTable({ columns, data }) {
  const isMobile = useMediaQuery('(max-width:600px)'); // Change the breakpoint to suit your design

  console.log("table data :", data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [alumniData, setAlumniData] = useState(data)
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdminUser, setIsAdminUser] = useState(false);
  useEffect(() => {
    const isAdmin = localStorage.getItem('type') === 'admin';
    setIsAdminUser(isAdmin);
  }, []);


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [show, setShow] = useState(false);
  const [showedit, setShowedit] = useState(false);
  const [showdelte, setShowdelete] = useState(false);
  const handleClose = () => setShow(false);

  const handleCloseedit = () => setShowedit(false);
  const handleShowedit = () => setShowedit(true);

  const handleClosedeleted = () => setShowdelete(false);


  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const filteredAlumniData = alumniData.filter(
    (alumni) =>
      alumni.A_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      alumni.A_NativePlace.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // const currentRecords = filteredAlumniData.slice(indexOfFirstRecord, indexOfLastRecord);

  // const totalPages = Math.ceil(alumniData.length / recordsPerPage);

  const handleClick = (type) => {
    if (type === 'prev') {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    } else if (type === 'next') {
      setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPages = Math.min(totalPages, 10);

    for (let i = 1; i <= maxPages; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item${currentPage === i ? ' active' : ''}`}>
          <Button className="page-link" onClick={() => paginate(i)}>
            {i}
          </Button>
        </li>
      );
    }
    return pageNumbers;
  };


  const handleDelete = (index) => {
    // Handle delete action based on the index
  };


  const [editData, setEditData] = useState({});
  const [editIndex, setEditIndex] = useState(null);


  const handleEdit = (index) => {
    setEditData(alumniData[index]);
    setEditIndex(index);
    handleShowedit(); // Show the edit modal
  };


  const handleEditInputChange = (event, field) => {
    const updatedData = { ...editData };
    updatedData[field] = event.target.value;
    setEditData(updatedData);
  };

  const handleSaveEdit = () => {
    const updatedAlumniData = [...alumniData];
    updatedAlumniData[editIndex] = editData;
    setAlumniData(updatedAlumniData);
    handleCloseedit();
  };

  //////
  const [filters, setFilters] = useState({
    batchFilter: '',
    passoutFilter: '',
    nativePlaceFilter: '',
    workingPlaceFilter: '',
    Occupation: ''
  });



  const handleFilterChange = (event, columnName) => {
    const { value } = event.target;
    setFilters({ ...filters, [columnName]: value });
  };



  const uniqueBatches = [...new Set(alumniData.map((alumni) => alumni.A_Batch))];
  const uniquePassoutYears = [...new Set(alumniData.map((alumni) => alumni.A_Passoutyear))];
  const uniqueNativePlaces = [...new Set(alumniData.map((alumni) => alumni.A_NativePlace))];
  const uniqueWorkingPlaces = [...new Set(alumniData.map((alumni) => alumni.A_WorkingPlace))];
  const uniqueOccupations = [...new Set(alumniData.map((alumni) => alumni.A_Occupation))];

  // const filteredData = alumniData.filter((alumni) => {
  //   return (
  //     alumni.A_Batch.toLowerCase().includes(filters.batchFilter.toLowerCase()) &&
  //     alumni.A_Passoutyear.toLowerCase().includes(filters.passoutFilter.toLowerCase()) &&
  //     alumni.A_NativePlace.toLowerCase().includes(filters.nativePlaceFilter.toLowerCase()) &&
  //     alumni.A_WorkingPlace.toLowerCase().includes(filters.workingPlaceFilter.toLowerCase()) &&
  //     alumni.A_Occupation.toLowerCase().includes(filters.Occupation.toLowerCase())

  // //   );
  // });

  const filteredData = alumniData.filter((alumni) => {
    try {
      // Perform the filtering operations
      const isBatchMatch = alumni.A_Batch.toLowerCase().includes(filters.batchFilter.toLowerCase());
      const isPassoutMatch = alumni.A_Passoutyear.toLowerCase().includes(filters.passoutFilter.toLowerCase());
      const isNativePlaceMatch = alumni.A_NativePlace.toLowerCase().includes(filters.nativePlaceFilter.toLowerCase());
      const isWorkingPlaceMatch = alumni.A_WorkingPlace.toLowerCase().includes(filters.workingPlaceFilter.toLowerCase());
      const isOccupationMatch = alumni.A_Occupation.toLowerCase().includes(filters.Occupation.toLowerCase());
  
      // Return true if all conditions are met
      return isBatchMatch && isPassoutMatch && isNativePlaceMatch && isWorkingPlaceMatch && isOccupationMatch;
    } catch (error) {
      // Handle the error if any
      console.error("Error while filtering alumni data:", error);
      // Return false to exclude the current alumni
    }})  
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  //   const [visibleColumns, setVisibleColumns] = React.useState(['Sl.No', 'Alumni', 'Name','Email','Batch','Passout','Native Place','Working Place'
  // ,'Linkdin','Phone Number','Occupation','Occupation Type']); // Example columns




  const handleDownloadExcel = () => {
    console.log("Downloading Excel Data.....")
    exportAlumniDataToExcel(alumniData);
  };
  return (

    <div class="container-fluid px-0 mx-0" >
      <div className="crud shadow-lg p-3 mb-5 bg-body rounded">
        <div class="row ">
          <div class="col-sm-3 mb-4 text-gred">
            <div className="search">
              <form class="form-inline">
                <input class="form-control mr-sm-2" type="search" placeholder="Search Alumni" aria-label="Search" value={searchQuery}
                  onChange={handleSearchInputChange} />
              </form>
            </div>
          </div>
          <div  className="col-sm-3 offset-sm-1 mb-4 text-gred" style={{ color: "#4cceac" }}><h2><b>Alumni Details</b></h2></div>
          {isAdminUser && (
         <div style={{width:'30%'}} className={`col-sm-3 offset-sm-1 mb-4 text-gred d-flex align-items-center justify-content-between justify-content-sm-end`}>
         <Button style={{ backgroundColor: '#020248', color: 'white', marginRight: isMobile ? '8px' : '16px' }} className={`p-2 ${isMobile ? 'pr-2' : 'pr-3'}`} href="/register" variant="secondary">
           Register Alumni
         </Button>
         {!isMobile && (
  <Box>
            <Button onClick={handleDownloadExcel}
            style={{width:'100%',backgroundColor: '#020248', color: 'white'}} className={`col-sm-3 offset-sm-1 text-gred d-flex align-items-center justify-content-between justify-content-sm-end`}
           
              sx={{
             
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box>
          //  <IconButton>
          //    <DownloadOutlinedIcon onClick={handleDownloadExcel} sx={{ fontSize: '26px', color: '#4cceac' }} />
          //  </IconButton>
         )}
       </div>
       
          )}
          {isMobile && isAdminUser && (
              <Box>
            <Button onClick={handleDownloadExcel}
            style={{ backgroundColor: '#020248', color: 'white'}}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon  sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box> 
        //   <div className="col-sm-3 offset-sm-1 mb-4 text-gred d-flex justify-content-end align-items-center">
        //   <IconButton>
        //     <DownloadOutlinedIcon onClick={handleDownloadExcel} sx={{ fontSize: '26px', color: '#4cceac' }} />
        //   </IconButton>
        // </div>
        
          )}


        </div>
        <div className="row mb-4 justify-content-end">
          <div className="col-md-2">
            
            <select
              className="form-control"
              value={filters.batchFilter}
              onChange={(e) => handleFilterChange(e, 'batchFilter')}
            >
              <option value="">Filter Batch</option>
              {uniqueBatches.map((batch, index) => (
                <option key={index} value={batch}>
                  {batch}
                </option>
              ))}
            </select>
            {/* <i className="material-icons position-absolute top-50 end-0 translate-middle-y" style={{ paddingRight: '15px' }}>
              arrow_drop_down
            </i> */}
          </div>
          <div className="col-md-2">
            <select
              className="form-control"
              value={filters.passoutFilter}
              onChange={(e) => handleFilterChange(e, 'passoutFilter')}
            >
              <option value="">Filter Passout Year</option>
              {uniquePassoutYears.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {/* <i className="material-icons position-absolute top-50 end-0 translate-middle-y" style={{ paddingRight: '15px' }}>
              arrow_drop_down
            </i> */}
          </div>
          <div className="col-md-2">
            <select
              className="form-control"
              value={filters.nativePlaceFilter}
              onChange={(e) => handleFilterChange(e, 'nativePlaceFilter')}
            >
              <option value="">Filter Native Place</option>
              {uniqueNativePlaces.map((place, index) => (
                <option key={index} value={place}>
                  {place}
                </option>
              ))}
            </select>
            {/* <i className="material-icons position-absolute top-50 end-0 translate-middle-y" style={{ paddingRight: '15px' }}>
              arrow_drop_down
            </i> */}
          </div>
          <div className="col-md-2">
            <select
              className="form-control"
              value={filters.workingPlaceFilter}
              onChange={(e) => handleFilterChange(e, 'workingPlaceFilter')}
            >
              <option value="">Filter Working Place</option>
              {uniqueWorkingPlaces.map((place, index) => (
                <option key={index} value={place}>
                  {place}
                </option>
              ))}
            </select>
            {/* <i className="material-icons position-absolute top-50 end-0 translate-middle-y" style={{ paddingRight: '15px' }}>
              arrow_drop_down
            </i> */}
          </div>

          <div className="col-md-2">
            <select
              className="form-control"
              value={filters.Occupation}
              onChange={(e) => handleFilterChange(e, 'Occupation')}
            >
              <option value="">Filter Occupation</option>
              {uniqueOccupations.map((occupation, index) => (
                <option key={index} value={occupation}>
                  {occupation}
                </option>
              ))}
            </select>
            {/* <i className="material-icons position-absolute top-50 end-0 translate-middle-y" style={{ paddingRight: '15px' }}>
              arrow_drop_down
            </i> */}
          </div>
        </div>

        <div class="row">
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Alumni</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Batch</th>
                  <th>Passout</th>
                  {/* <th>Education</th> */}
                  <th>District</th>
                  <th>Native Place</th>
                  <th>Working Place</th>
                  <th>Linkdin</th>
                  <th>Phone Number</th>
                  <th>Occupation</th>
                  <th>Occupation Type</th>
                  {isAdminUser && (
                    <th>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredAlumniData.length === 0 ? (
                  <tr>
                    <td colSpan="14">
                      <div className="justify-content-center">
                        <div className="alert alert-danger" role="alert">
                          <b style={{ textAlign: 'center' }}>No results found</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  currentRecords.map((alumni, index) => (

                    <tr key={index}>
                      <td>{indexOfFirstRecord + index + 1}</td>
                      <td>
                        <div style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                        }}>
                          {alumni.A_profile ? (
                            <img
                            // src="https://media.licdn.com/dms/image/D5603AQH2E2iLALl5vw/profile-displayphoto-shrink_800_800/0/1695904842198?e=1709769600&v=beta&t=Et--zZWmQoxVJ-W5GvG6aK5ym0YQ6VeXbuIVNPafpEA"
                              src={`https://jnvalumniassociation.co.in/public/images/${alumni.A_profile}`}
                              alt="alumni"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (

                            <img
                            // src="https://media.licdn.com/dms/image/D5603AQH2E2iLALl5vw/profile-displayphoto-shrink_800_800/0/1695904842198?e=1709769600&v=beta&t=Et--zZWmQoxVJ-W5GvG6aK5ym0YQ6VeXbuIVNPafpEA"

                              src={"https://demofree.sirv.com/nope-not-here.jpg"}
                              alt="placeholder"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                      </td>

                      <td>{alumni.A_Name ? alumni.A_Name : "No Data"}</td>
                      <td>{alumni.A_Email ? alumni.A_Email : "No Data"}</td>
                      <td>{alumni.A_Batch ? alumni.A_Batch : "No Data"}</td>
                      <td>{alumni.A_Passoutyear ? alumni.A_Passoutyear : "No Data"}</td>
                      {/* <td>{alumni.A_Education ? alumni.A_Education : "No Data"}</td> */}
                      <td>{alumni.A_Ditsrict ? alumni.A_Ditsrict : "No Data"}</td>

                      <td>{alumni.A_NativePlace ? alumni.A_NativePlace : "No Data"}</td>
                      <td>{alumni.A_WorkingPlace ? alumni.A_WorkingPlace : "No Data"}</td>
                      <td>{alumni.A_Linkdin ? alumni.A_Linkdin : "No Data"}</td>
                      <td>{alumni.A_Number ? alumni.A_Number : "No Data"}</td>
                      <td>{alumni.A_Occupation ? alumni.A_Occupation : "No Data"}</td>
                      <td>{alumni.A_OccupationType ? alumni.A_OccupationType : "No Data"}</td>
                      {/* <td>
                  <a href="#" class="view" title="View" data-toggle="tooltip" style={{color:"#10ab80"}}><i class="material-icons">&#xE417;</i></a>
                  <a href="#" class="edit" title="Edit"  data-toggle="tooltip"><i onClick={handleShowedit} class="material-icons">&#xE254;</i></a>
                  <a href="#" class="delete" title="Delete" data-toggle="tooltip" style={{color:"red"}}><i class="material-icons">&#xE872;</i></a>
                  </td> */}
                      {isAdminUser && (
                        <td>
                          <Button variant="secondary" onClick={() => handleEdit(index)}>
                            <i onClick={handleShowedit} class="material-icons">&#xE254;</i>
                          </Button>
                          &nbsp;
                          <br></br>
                          <Button variant="secondary" onClick={() => handleDelete(index)}>
                            <i class="material-icons">&#xE872;</i>
                          </Button>

                        </td>
                      )}
                    </tr>
                  )))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            <ul className="pagination">
              <li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
                <Button className="page-link" onClick={() => handleClick('prev')}>
                  Prev
                </Button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item${currentPage === totalPages ? ' disabled' : ''}`}>
                <Button className="page-link" onClick={() => handleClick('next')}>
                  Next
                </Button>
              </li>
            </ul>
          </div>
        </div>
        {/* <!--- Model Box ---> */}
        <div className="model_box">
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <form>
                <div class="form-group">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alumni Name"/>
                </div>
                <div class="form-group mt-3">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Place"/>
                </div>
                <div class="form-group mt-3">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City"/>
                </div>
                <div class="form-group mt-3">
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Phone Number"/>
                </div>
                
                  <button type="submit" class="btn btn-success mt-4">Add Record</button>
                </form> */}
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>

          {/* Model Box Finsihs */}
        </div>

        <div className="model_box">
          <Modal
            show={showdelte}
            onHide={handleClosedeleted}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div class="form-group">
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" />
                </div>
                <div class="form-group mt-3">
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Country" />
                </div>
                <div class="form-group mt-3">
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City" />
                </div>
                <div class="form-group mt-3">
                  <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Country" />
                </div>

                <button type="submit" class="btn btn-success mt-4">Add Record</button>
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>

          {/* Model Box Finsihs */}
        </div>











        <div className="model_box">
          <Modal show={showedit} onHide={handleCloseedit} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Alumni Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Name"
                      placeholder="Enter Name"
                      value={editData.A_Name || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Name')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Email"
                      placeholder="Enter Email"
                      value={editData.A_Email || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Email')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Batch">Batch</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Batch"
                      placeholder="Enter Batch"
                      value={editData.A_Batch || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Batch')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Passoutyear">Passout Year</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Passoutyear"
                      placeholder="Enter Passout Year"
                      value={editData.A_Passoutyear || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Passoutyear')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_NativePlace">Native Place</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_NativePlace"
                      placeholder="Enter Native Place"
                      value={editData.A_NativePlace || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_NativePlace')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_WorkingPlace">Working Place</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_WorkingPlace"
                      placeholder="Enter Working Place"
                      value={editData.A_WorkingPlace || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_WorkingPlace')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Linkdin">LinkedIn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Linkdin"
                      placeholder="Enter LinkedIn"
                      value={editData.A_Linkdin || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Linkdin')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Number">Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Number"
                      placeholder="Enter Number"
                      value={editData.A_Number || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Number')}
                    />
                  </div>


                </div>
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_Occupation">Occupation</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_Occupation"
                      placeholder="Enter Occupation"
                      value={editData.A_Occupation || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_Occupation')}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="editField-A_OccupationType">Occupation Type</label>
                    <input
                      type="text"
                      className="form-control"
                      id="editField-A_OccupationType"
                      placeholder="Enter Occupation Type"
                      value={editData.A_OccupationType || ''}
                      onChange={(e) => handleEditInputChange(e, 'A_OccupationType')}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseedit}>
                Close
              </Button>
              <Button variant="secondary" onClick={handleSaveEdit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>





      </div>
    </div>
  );
}

export default AlumniTable;

