import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AlertMessage({ showModal, handleClose }) {
    return (
        <Modal show={showModal} onHide={handleClose}
            size="lg"
            centered        >
            <Modal.Header closeButton>
                <Modal.Title>Testing....</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, Working On It....!</Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: 'rgb(2, 2, 72)' }} onClick={handleClose}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
    );
}
export default AlertMessage;