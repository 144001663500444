import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
     <Box width="100%" m="0 30px">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box mb={2}>
          {icon}
        </Box>
        <Typography variant="h4" fontWeight="bold" sx={{ color: 'black' }}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
        <Typography variant="h5" fontWeight="bold" sx={{ color: 'black'}}>
          {subtitle}
        </Typography>
        <Typography variant="h5" fontStyle="italic" sx={{ color: 'darkgreen' }}>
          {increase}
        </Typography>
      </Box>
      {progress && (
        <Box display="flex" justifyContent="center" mt={2}>
          {/* Include your Progress component here */}
          {progress}
        </Box>
      )}
    </Box>
    // <Box width="100%" m="0 30px">
    //   <Box display="flex" justifyContent="space-between">
    //     <Box>
    //       {icon}
    //       <Typography
    //         variant="h4"
    //         fontWeight="bold"
    //         sx={{ color: colors.grey[100] }}
    //       >
    //         {title}
    //       </Typography>
    //     </Box>
    //     <Box>
    //       <ProgressCircle progress={progress} />
    //     </Box>
    //   </Box>
    //   <Box display="flex" justifyContent="space-between" mt="2px">
    //     <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
    //       {subtitle}
    //     </Typography>
    //     <Typography
    //       variant="h5"
    //       fontStyle="italic"
    //       sx={{ color: colors.greenAccent[600] }}
    //     >
    //       {increase}
    //     </Typography>
    //   </Box>
    // </Box>
  );
};

export default StatBox;
