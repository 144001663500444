import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React, { useState, useRef, useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
import servername from "../constants";
// import testVideo from './test.mp4';
import testVideo1 from './1v.mp4';
import testVideo2 from './2v.mp4';
import testVideo3 from './3v.mp4';
import testVideo4 from './4v.mp4';

function MoreDetails() {
    const [refresh, setrefresh] = useState(false);
    const [currentTheme, setCurrentTheme] = useTheme();
    const [eventdata, seteventdata] = useState([]);
    let navigate = useNavigate();
    
    function cancel() {
        setrefresh(false);
        window.location.reload();
    }
    const videoUrls = [
        testVideo1,
        testVideo2,
        testVideo3,
        testVideo4
        // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
        // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
        // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
    ];
    function getRandomVideoUrl() {
        const randomIndex = Math.floor(Math.random() * videoUrls.length);
        return videoUrls[randomIndex];
    }

    const testVideo = getRandomVideoUrl();

    useEffect(() => {
        if (!localStorage.getItem('login_key')) {
            // user is not authenticated
            navigate('/login');
        }

    });

    return (
        <React.Fragment>
            <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
            
            <main className="mainclass"  >
                {/* "https://www.w3schools.com/tags/movie.mp4" */}
               
                <div className='row'>
                    <div className="col-md-5">
                        <a href='/alumnus'><button className="btn btn-secondary"><i class="fa fa-arrow-left" aria-hidden="true"></i></button></a>
                    </div>
                    <div className="video-container" style={{ marginTop: '155px' }}>
                        <video className="background-video" autoPlay loop muted controls>
                            <source src={testVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>

            </main>
        </React.Fragment>

    );
}

export default MoreDetails