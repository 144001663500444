import * as XLSX from 'xlsx';

function exportAlumniDataToExcel(alumniData) {
  const columns = Object.keys(alumniData[0]); // Assuming the first object defines all columns

  // Define mapping for column names
  // const columnMapping = {
  //   A_Name: 'Name',
  //   A_Number: 'Phone Number',
  //   A_Email: 'Email ID',
  //   A_Passoutyear: 'Passout Year',
  //   A_Occupation: 'Occupation',
  //   A_Batch: 'Batch',
  //   A_Education: 'Education',
  //   A_Linkdin: 'Linkdin',
  //   A_NativePlace: 'Native Place',
  //   A_WorkingPlace: 'Working Place',
  //   // A_profile: 'Profile Verified',
  //   // A_District: 'District',
  //   // A_OccupationType: 'Occupation Type',
  //   // verified: 'verified', // If this field name doesn't need modification
  //   // A_Ditsrict: 'Ditsrict' // Correcting the typo
  // };

  const columnMapping = { 
  }
  // const fieldsToExclude = ['_id', 'A_Password', 'Password','A_District','A_OccupationType','verified','A_Ditsrict','Created Time','A_profile'];
  const fieldsToExclude = [];

  // Remove excluded fields from columns
  const filteredColumns = columns
    .filter(column => !fieldsToExclude.includes(column))
    .map(column => columnMapping[column] || column);

  const wb = XLSX.utils.book_new();

  // Map alumni data to match the desired columns and exclude fields
  const filteredData = alumniData.map(alumni => {
    const filteredAlumni = {};
    Object.keys(alumni).forEach(column => {
      if (!fieldsToExclude.includes(column)) {
        filteredAlumni[columnMapping[column] || column] = alumni[column] ;
      }
    });
    return filteredAlumni;
  });

  // Convert filtered data to an array of arrays
  const dataAsArray = filteredData.map(alumni => filteredColumns.map(column => alumni[column]));

  // Add headers to the data
  dataAsArray.unshift(filteredColumns);

  // Convert the data to a worksheet
  const ws = XLSX.utils.aoa_to_sheet(dataAsArray);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Alumni Data');

  // Generate the Excel file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  // Convert the binary string to a Blob
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = 'alumni_data.xlsx';
  link.click();

  // Function to convert string to ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
}

export default exportAlumniDataToExcel;
