import { Box, IconButton, useTheme,Typography,ButtonBase } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import  LightModeOutlinedIcon  from "@mui/icons-material/LightModeOutlined";
import  DarkModeOutlinedIcon  from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center"  bgcolor="white" p={3}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}

      {/* HEADINGS */}
      <Box display="flex" gap={4}>
        <ButtonBase href="/analytics" sx={{ borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'black', borderBottom: '1px solid black', paddingBottom: '2px' }}>
            Home
          </Typography>
        </ButtonBase>
        <ButtonBase href="/analytics"  sx={{ borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'black', borderBottom: '1px solid black', paddingBottom: '2px' }}>
            Analytics
          </Typography>
        </ButtonBase>
        <ButtonBase href="/locations" sx={{ borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'black', borderBottom: '1px solid black', paddingBottom: '2px' }}>
          Find Alumni Locations
          </Typography>
        </ButtonBase>
        {/* <ButtonBase sx={{ borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'black', borderBottom: '1px solid black', paddingBottom: '2px' }}>
            Payment
          </Typography>
        </ButtonBase> */}
      </Box>

      {/* OPTIONS */}
      {/* <Box display="flex" gap={2}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
      </Box> */}
    </Box>
  );
};

export default Topbar;
