import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from "../theme";
import Appbar from "../appbar";
import Box from "@material-ui/core/Box";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useRef, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import servername from "../constants";
import Compress from "react-image-file-resizer";
import Divider from '@material-ui/core/Divider';
import profile_im_user from "./user.png";
export default function Profile (){
    const [currentTheme, setCurrentTheme] = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loadopen, setLoadopen] = React.useState(true);

  const [maindata, setmainData] = useState([]);
  const [selectedAlumini, setSelectedAlumini] = useState(null);
  const [dalaavailable, setDalaAvailable] = useState(false);
  const [editeddata, setediteddata] = useState([]);
  const [maindiv, setmaindiv] = useState(true);
  const [editdiv, seteditdiv] = useState(false);

  const [batch, setBatch] = useState('');
 
  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Passoutyear, setPassoutyear] = useState('');
  const[Linkdin,setLinkdin]=useState('');
  const[Education,setEducation]=useState('');
  const [nameerror, setnameerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const[numerror, setnumerror] = useState(false);
  const[occupationerror, setoccupationerror] = useState(false);
  const[educationerror, seteducationerror] = useState(false);
  const[batcherror, setbatcherror] = useState(false);
  const[loadopen1, setloadopen] = useState(false);
  const[alertopen, setalertopen] = useState(false);
  const[erroralertopen, seterroralertopen] = useState(false);
  const[NativePlace,setNativePlace] = useState('');
  const[WorkingPlace,setWorkingPlace] = useState('');
  const[docid, setdocid] = useState('');
  const [s_data, setsdata] = useState('');
  const[sb_data, setsb_data] = useState('');
  const[searchdiv, setsearchdiv] = useState(false);
  const[imagezie, setimagezie] = useState(false);
  const[imageload, setimageload] = useState(false);
  const[profileimage,setprofileimage] = useState();
  const[profile_im, setprofile_im] = useState('');
  let navigate = useNavigate ();
  const handleClickOpen = (index) => {
    setSelectedAlumini(index);
  };

  function imagesize(p_image){
    ////console.log(p_image.size);
    if(p_image.size > 50000000){
     setimagezie(true);
    }else{
     setimagezie(false);
    }
     }
const editprofile =  () =>{
     seteditdiv(true);
    setmaindiv(false);
}
  const EditData = (id) => {
    setdocid(id._id );
   
       ////console.log(id._id);
       setBatch(id.A_Batch);
       setName(id.A_Name);
       setEmail(id.A_Email);
       setOccupation(id.A_Occupation);
       setNativePlace(id.A_NativePlace);
       setWorkingPlace(id.A_WorkingPlace);
       setPassoutyear(id.A_Passoutyear);
       setEducation(id.A_Education);
       setNumber(id.A_Number);
       setLinkdin(id.A_Linkdin);
       setprofile_im('https://jnvalumniassociation.co.in/public/images/' + id.A_profile);
      //  setprofile_im('http://localhost:2024/public/images/' + id.A_profile);
      //  const requestOptions1 = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ id: id._id }),
      // };
      // fetch("${servername}/alumni_list_data", requestOptions1)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     ////console.log('data', data);
      //    });
  };

  useEffect(() => {
    ////console.log(servername);
    if (!localStorage.getItem('login_key')) {
      // user is not authenticated
      navigate('/login');
    }
    setOpen(true);
  }, [selectedAlumini]);

  function alumnidata() {
    var email = localStorage.getItem('email');
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Email: email }),
    };
    fetch(`${servername}/alumni_list_email`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setmainData(data);
        setLoadopen(false);
        setsearchdiv(true);
        EditData(data[0]);
        if(data.length == 0){
          setDalaAvailable(true);
          setsearchdiv(false);
        }
        ////console.log("length", maindata.length);
      });
  }

  useEffect(() => {
    alumnidata();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedAlumini(null);
  };
 

  const options = ['1987-1988 -  1st','1988-1989 - 2nd','1989-1990 - 3rd','1990-1991 - 4th','1991-1992 - 5th','1992-1993 - 6th','1993-1994 - 7th','1994-1995 - 8th','1995-1996 - 9th','1996-1997 - 10th','1997-1998 - 11th','1998-1999 - 12th','1999-2000 - 13th','2000-2001 - 14th','2001-2002 - 15th','2002-2003 - 16th','2003-2004 - 17th','2004-2005 - 18th','2005-2006 - 19th','2006-2007 - 20th','2007-2008 - 21st','2008-2009 - 22nd','2009-2010 - 23rd','2010-2011 - 24th','2011-2012 - 25th','2012-2013 - 26th','2013-2014 - 27th','2014-2015 - 28th','2015-2016 - 29th','2016-2017 - 30th','2017-2018 - 31st','2018-2019 - 32nd','2019-2020 - 33th','2020-2021 - 34th','2021-2022 - 35th','2022-2023 - 36th'
];
      const defaultOption = batch;
   
      function submitform(){ 
        
        ////console.log('batch', docid);
        if(Name==''){
          setnameerror(true);
        }
        if(Number==''){
          setnumerror(true);
        }
        if(Email==''){
          setemailerror(true);
        }
        if(Occupation==''){
          setoccupationerror(true);
        }
        if(Education==''){
          seteducationerror(true);
        }
        if(batch==''){
          setbatcherror(true);
        }
       else{ 

        if(Name !=''&& Email !='' && Occupation!='' && Education !='' && batch!=''){
          setloadopen(true);

          if(imageload){
            var filename = profileimage.name;
            ////console.log(profileimage);
            var reader = new FileReader();
    
            reader.onload = function (e) {
              Compress.imageFileResizer(
                profileimage, // the file from input
                100, // width
                100, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                100, // quality
                0, // rotation
                (uri) => {
                  ////console.log(uri);
                  const requestOptions= {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({url:uri,filename:filename})
                };
                fetch(`${servername}/profile_image_upload`, requestOptions)
                    .then(response => response.json())
                    .then((data)=>{
                     
                     ////console.log(data);
                     const requestOptions= {
                      method: 'PUT',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ A_Name:Name.trim(),A_Number:Number,A_Email:Email,A_Passoutyear:Passoutyear,A_Occupation:Occupation.trim(),A_Batch:batch,A_Education:Education.trim(),A_Linkdin :Linkdin, A_NativePlace:NativePlace.trim() , A_WorkingPlace :WorkingPlace.trim(), A_profile:data})
                  };
                  fetch(`${servername}/alumni_list_data/${docid}`, requestOptions)
                  .then(response => response.json())
                  .then((data)=>{
                    if(data['lastErrorObject']['updatedExisting']){
                      setloadopen(false);
                      setalertopen(true);
                      
                    }else{
                      seterroralertopen(true);
                    }
                   ////console.log(data['lastErrorObject']['updatedExisting']);
                  
                   //alert.show('Oh look, an alert!')
                   //alert("successfully You have registerd");
                 
                  })
                      .catch(error => {
                        throw(error);
                    });
                   
                   
                    })
                    .catch(error => {
                      throw(error);
                  });
                  // You upload logic goes here
                },
                "base64" // blob or base64 default base64
              );
          // ////console.log(e.target.result);
         
          //  navigator.clipboard.writeText(e.target.result);
            }
      
         reader.readAsDataURL(profileimage);




          }else{
            const requestOptions= {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ A_Name:Name.trim(),A_Number:Number,A_Email:Email,A_Passoutyear:Passoutyear,A_Occupation:Occupation.trim(),A_Batch:batch,A_Education:Education.trim(),A_Linkdin :Linkdin, A_NativePlace:NativePlace.trim() , A_WorkingPlace :WorkingPlace.trim(), A_profile:''})
          };
          fetch(`${servername}/alumni_list_data/${docid}`, requestOptions)
              .then(response => response.json())
              .then((data)=>{
                if(data['lastErrorObject']['updatedExisting']){
                  setloadopen(false);
                  setalertopen(true);
                  
                }else{
                  seterroralertopen(true);
                }
               ////console.log(data['lastErrorObject']['updatedExisting']);
              
               //alert.show('Oh look, an alert!')
               //alert("successfully You have registerd");
             
              })
              .catch(error => {
                throw(error);
            });
          }


        
           // ////console.log(Name);
        }
        
       else{
          return false;
        }
      }
      
     
      }

     function cancel(){
      window.location.reload();
     }

      const handleClickToOpen1 = () => {
        setalertopen(true);
      };
      
      const handleToClose1 = () => {
        setalertopen(false);
        seterroralertopen(false);
      }
      return (
        <React.Fragment>
          <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
    
          <main className="mainclass" >
            {maindiv && (
                <center>
                        
                        <div  style={{ backgroundColor: 'rgb(212 211 250)' , padding: '20px',borderRadius: '30px'  }} className="MainDivp"  >
                        <div style={{paddingTop: '10px'}}><Typography><h3>Profile</h3> </Typography></div>
                            <div className="row" style={{marginRight: '0px', marginLeft: '0px'}}>
                            <div className="col-md-4 profilegrid4" >
                            { profile_im ==='' && <img className="profileim" src={profile_im_user} alt="Logo" style={{paddingTop: '5px'}} />}
                            { profile_im !=='' &&  <img className="profileim" src={profile_im} alt="Logo" style={{marginTop: '10px'}} />}
                            <Typography style={{paddingTop: '10px'}}><h5>{Name}</h5> </Typography>
                            <Typography>{Occupation}</Typography>
                            <Typography>{NativePlace}</Typography>
                            <div>
                            <button className="btn btn-primary" style={{width: '130px',
                            marginTop: '20px', marginBottom:'10px'}} onClick={editprofile}><Typography>Edit Profile</Typography></button>
                            </div>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-md-7 profilegrid7" style={{paddingLeft:'30px'}}>
                                <div className="row" style={{marginTop:'10px'}}> 
                                    <div className="col-4"> <Typography>Full Name</Typography></div>
                                    <div className="col-8"><Typography>{Name}</Typography></div>
                                </div>
                                <Divider />
                                <div className="row" style={{marginTop:'10px'}}>
                                    <div className="col-4"> <Typography>Email</Typography></div>
                                    <div className="col-8"><Typography>{Email}</Typography></div>
                                </div>
                                <Divider />
                                <div className="row" style={{marginTop:'10px'}}>
                                    <div className="col-4"> <Typography>Mobile</Typography></div>
                                    <div className="col-8"><Typography>{Number}</Typography></div>
                                </div>
                                <Divider />
                                <div className="row" style={{marginTop:'10px'}}>
                                    <div className="col-4"> <Typography>Batch</Typography></div>
                                    <div className="col-8"><Typography>{batch}</Typography></div>
                                </div>
                                <Divider />
                                <div className="row" style={{marginTop:'10px'}}>
                                    <div className="col-4"> <Typography>Native Place</Typography></div>
                                    <div className="col-8"><Typography>{NativePlace}</Typography></div>
                                </div>
                                <Divider />
                                <div className="row" style={{marginTop:'10px'}}>
                                    <div className="col-4"> <Typography>Working Place</Typography></div>
                                    <div className="col-8"><Typography>{WorkingPlace}</Typography></div>
                                </div>

                        
                            
                            </div>
                           
                            </div>
                        </div>
                </center>
            )}
          {editdiv && (
           <center>
           <div  style={{ backgroundColor: 'rgb(252 252 252)' , padding: '50px',borderRadius: '30px' }} className="MainDiv"  >
          
           
          <div>
           <Dialog open={alertopen} onClose={handleToClose1}>
                 <DialogTitle style={{color:'green'}}>Success</DialogTitle>
                 <DialogContent>
                   <DialogContentText style={{color:'black'}}>
                    You Have updated successfully 
                   </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                   <Button onClick={handleToClose1} 
                           color="primary" autoFocus>
                     Close
                   </Button>
                 </DialogActions>
               </Dialog>
         
         
               <Dialog open={erroralertopen} onClose={handleToClose1}>
                 <DialogTitle style={{color:'red'}}>Alert!!</DialogTitle>
                 <DialogContent>
                   <DialogContentText style={{color:'black'}}>
                    Data not updated !!!
                   </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                   <Button onClick={handleToClose1} 
                           color="primary" autoFocus>
                     Close
                   </Button>
                 </DialogActions>
               </Dialog>
         
          {loadopen1 && <div className="loader1" ></div>  }
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
           <Typography style={{fontSize: '24px'}}>Edit Profile</Typography>
          </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                     required
                     id="fullname"
                     name="FullName"
                     label="FullName"
                     value={Name}
                     fullWidth
                     inputProps={{
                       autoComplete: 'off'
                    }}
                     variant="standard"
                     onChange={(value)=>setName(value.target.value)}
                     
                   />
                  {nameerror && <Typography style={{color:'red',textAlign:'left'}}>Name Can't be blank!!!</Typography>} 
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="mobileNumber"
                     name="Mobile Number"
                     label="Mobile Number"
                     value={Number}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setNumber(value.target.value)}
                   />
                   {numerror && <Typography style={{color:'red',textAlign:'left'}}>Number Can't be blank!!!</Typography>}
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="email"
                     name="email"
                     label="Email"
                     value={Email}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setEmail(value.target.value)}
                   />
                   {emailerror && <Typography style={{color:'red',textAlign:'left'}}>Email Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                    // required
                     id="pass_out_year"
                     name="pass_out_year"
                     label="Pass Out Year"
                     value={Passoutyear}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setPassoutyear(value.target.value)}
                   />
                   {/* <Typography style={{color:'red',textAlign:'left'}}>Name Can't be blank!!!</Typography> */}
                 </Grid><Grid item xs={12} sm={6} >
                    
                
                  <div className="row gridclass">
                   <div className="col-6" style={{paddingLeft: '0px' ,    marginLeft: '-10px'}}><Typography style={{fontSize:'18px'}}>Select Batch:</Typography></div>
                   <div className="col-6"><Dropdown options={options} onChange={(value)=>setBatch(value['value'])}  value={defaultOption} placeholder="Select an option" /></div>
                   </div>   
                   {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>}
              
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="occupation"
                     name="occupation"
                     label="Occupation"
                     value={Occupation}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setOccupation(value.target.value)}
                   />
                   {occupationerror && <Typography style={{color:'red',textAlign:'left'}}>Occupation Can't be blank!!!</Typography>}
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="education"
                     name="Education"
                     label="Education"
                     value={Education}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setEducation(value.target.value)}
                   />
                   {educationerror && <Typography style={{color:'red',textAlign:'left'}}>Education Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     //required
                     id="linkdin_profile"
                     name="Linkdin_profile"
                     label="Linkdin Profile"
                     value={Linkdin}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setLinkdin(value.target.value)}
                   />
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     // required
                     id="native_place"
                     name="native_place"
                     label="Native Place"
                     value={NativePlace}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setNativePlace(value.target.value)}
                   />
                   {educationerror && <Typography style={{color:'red',textAlign:'left'}}>Education Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     //required
                     id="working_Place"
                     name="working_Place"
                     label="Working Place"
                     value={WorkingPlace}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setWorkingPlace(value.target.value)}
                   />
                 </Grid>
                 <Grid item xs={12} sm={7}>
        <div className="input-group mb-3" >
  <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style={{borderBottomRightRadius: '0px', borderTopRightRadius: '0px'}}><Typography>Profile Image</Typography></span>
  </div>
  <input accept="image/*" type="file" className="form-control" style={{paddingTop: '4px',paddingLeft: '5px'}} placeholder="profileimage" id="profileimage" aria-label="Username" aria-describedby="basic-addon1"  onChange={(value) =>{ setprofileimage(value.target.files[0]); imagesize(value.target.files[0]); setimageload(true);}} />
       </div>
       {imagezie && <Typography style={{color:'red',textAlign:'left'}}>Image Size Shoudn't be morethen 5MB!!</Typography>}
      
        
        </Grid>
                 <Grid item xs={12} sm={12}>
                  <button className="btn btn-primary" style={{width: '230px',
             marginTop: '20px'}} onClick={submitform}><Typography>Update Form</Typography></button>
                <button className="btn btn-primary btn-pad"  style={{width: '230px',
             marginTop: '20px'}} onClick={cancel}><Typography>Cancel</Typography></button>
                 </Grid>
             </Grid> 
          </div>
              
             </div>
           </center>
          )}
             </main>
          </React.Fragment>
          )
}