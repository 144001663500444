import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import React , {useState} from "react";
import Modal from "./PopupModal";

const LineChart = ({ isCustomLineColors = false, isDashboard = false ,data ,finaldata }) => {

  console.log("line chart:",finaldata)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  // const { batches, alumni_count } = data;

  // Initial setup and data processing
const alumniPerBatch = {};
finaldata.forEach((alumnus) => {
  const { A_Batch } = alumnus;
  if (!alumniPerBatch[A_Batch]) {
    alumniPerBatch[A_Batch] = [];
  }
  alumniPerBatch[A_Batch].push(alumnus);
});

const alumniCountPerBatch = Object.entries(alumniPerBatch).map(([batch, alumni]) => ({
  batch,
  count: alumni.length,
  alumniDetails: alumni,
}));

// State for selected batch data
const [selectedBatchData, setSelectedBatchData] = useState(null);

// Function to handle bar click
const handleLineBarclick = (event, barData) => {
  console.log('Bar Click Event:', event);
  console.log('Bar Click Data:', barData);

  const batch = event.data.x;
  const alumniDetails = event.data.details; // Ensure this structure is as expected

  setSelectedBatchData({ batch, alumniDetails });
};

const handleCloseModal = () => {
  setSelectedBatchData(null);
};

  const lineChartData = [
    {
      id: 'Alumnus',
      color: '#f87979',
      data: alumniCountPerBatch.map((entry) => ({
        x: entry.batch, // Using the batch as x-value
        y: entry.count, // Using the count as y-value
        details: entry.alumniDetails, // Passing the alumniDetails for the tooltip or further details
      })),
    },
  ];
  return (
    <>
    <ResponsiveLine

    onClick={handleLineBarclick}


      data={lineChartData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
     colors={isDashboard ? { datum: 'color' } : { scheme: 'nivo' }}
  margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
  xScale={{ type: 'point' }}
  yScale={{
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: true,
    reverse: false,
  }}
  yFormat=' >-.2f'
  curve='catmullRom'
  axisTop={null}
  axisRight={null}
  axisBottom={{
    orient: 'bottom',
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 30,
    legend: isDashboard ? undefined : 'transportation',
    legendOffset: -40,
    legendPosition: 'middle',
    tickValues: 5

  }}
  axisLeft={{
    orient: 'left',
    tickValues: 5,
    tickSize: 3,
    tickPadding: 5,
    tickRotation: 0,
    legend: isDashboard ? undefined : 'count',
    legendOffset: -40,
    legendPosition: 'middle',
  }}
  enableGridX={false}
  enableGridY={false}
  pointSize={8}
  pointColor={{ theme: 'background' }}
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabelYOffset={-12}
  useMesh={true}
  legends={[
    {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        },
      ],
    },
  ]}
  tooltip={({ point }) => {
    return (
      <div>
        <strong>Batch:</strong> {point.data.xFormatted}
        <br />
        <strong>Count:</strong> {Math.round(point.data.y)}
      </div>
    );
  }}
    />
     {selectedBatchData && (
        <Modal message={'Alumni Details For Batch'} data={selectedBatchData} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default LineChart;