import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import AlertMessage from '../../components/Alertmsgs';
import Modal from '../../components/PopupModal';
import { OverlayView } from 'google-map-react';

const LocationShow = ({ text, onClick }) => {
  // console.log("location text :", text)
  return (
    <div
      onClick={onClick}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="https://i.pinimg.com/736x/eb/23/fe/eb23feeea3c34b597fdd4e655d18aa3e.jpg"
          alt="Marker"
          style={{
            width: '40px',
            height: '40px',
            marginRight: '5px',
            borderRadius: '50%', 
            objectFit: 'cover', 
          }}
        />
        {/* <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'white', fontWeight: 'bold', fontSize: '30px', marginRight: '5px' }} /> */}
        <strong>
          <span style={{ color: 'white', fontSize: '13px' }}>{text}</span>
        </strong>

      </div>
    </div>
  );
};
const ModalOverlay = ({ children, onClose }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      zIndex: 9999, // Place it on top of everything
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    onClick={onClose} // Close the modal when clicking outside
  >
    {children}
  </div>
);
const Map = (alumniData) => {

  console.log("Map Data :", alumniData)
  const center = {
    lat: 15.3647, lng: 75.1240
    // lat: 15.3173,
    // lng: 75.7139,
  };

  const zoom = 8;

  const districts = [
    { name: 'Bagalkot', lat: 16.1852, lng: 75.6966 },
  { name: 'Ballari', lat: 15.1394, lng: 76.9214 },
  { name: 'Belagavi', lat: 15.8497, lng: 74.4977 },
  { name: 'Bengaluru Rural', lat: 12.8997, lng: 77.5011 },
  { name: 'Bengaluru Urban', lat: 12.9716, lng: 77.5946 },
  { name: 'Bidar', lat: 17.9229, lng: 77.5175 },
  { name: 'Chamarajanagar', lat: 11.9236, lng: 76.9395 },
  { name: 'Chikkaballapur', lat: 13.4352, lng: 77.7375 },
  { name: 'Chikkamagaluru', lat: 13.3153, lng: 75.7754 },
  { name: 'Chitradurga', lat: 14.2226, lng: 76.4006 },
  { name: 'Dakshina Kannada', lat: 12.8393, lng: 75.1487 },
  { name: 'Davanagere', lat: 14.4644, lng: 75.9214 },
  { name: 'Dharwad', lat: 15.3647, lng: 75.1240 },
  { name: 'Gadag', lat: 15.4255, lng: 75.6250 },
  { name: 'Hassan', lat: 13.0058, lng: 76.0997 },
  { name: 'Haveri', lat: 14.7937, lng: 75.3983 },
  { name: 'Kalaburagi', lat: 17.3297, lng: 76.8343 },
  { name: 'Kodagu', lat: 12.3375, lng: 75.8069 },
  { name: 'Kolar', lat: 13.1364, lng: 78.1292 },
  { name: 'Koppal', lat: 15.3509, lng: 76.1540 },
  { name: 'Mandya', lat: 12.5242, lng: 76.8959 },
  { name: 'Mysuru', lat: 12.2958, lng: 76.6394 },
  { name: 'Raichur', lat: 16.2076, lng: 77.3463 },
  { name: 'Ramanagara', lat: 12.7169, lng: 77.3630 },
  { name: 'Shivamogga', lat: 13.9299, lng: 75.5681 },
  { name: 'Tumakuru', lat: 13.3392, lng: 77.1140 },
  { name: 'Udupi', lat: 13.3409, lng: 74.7421 },
  { name: 'Uttara Kannada', lat: 14.8776, lng: 74.5856 },
  { name: 'Vijayapura', lat: 16.8352, lng: 75.7139 },
  { name: 'Yadgir', lat: 16.7639, lng: 77.1384 }
  ];

  districts.forEach((district) => {
    district.count = Math.floor(Math.random() * 6) + 10; // Random number between 10 and 15
  });



  const [showModal, setShowModal] = useState(false);
  const [selectedAlumni, setSelectedAlumni] = useState([]);


  const handleMarkerClick = (name, count) => {
    console.log(count);
    const filteredAlumni = { 'batch': name, 'alumniDetails': alumniData['alumniData'].slice(0, count) };
    setSelectedAlumni(filteredAlumni);
    console.log(selectedAlumni)
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedAlumni([]); // Clear the selected alumni data
  };

  const locations = [
    { name: 'Bangalore', count: 1 },
    // { name: 'Mangalore', count: 2 },
    // Add more locations here...
  ];

  const apiKey = 'AIzaSyCEf_w2HrAMgYx9ppvpIe5GIWSJiffRQnU';

  const fetchCoordinates = async (location) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${location.name}&key=${apiKey}`
      );
      const data = await response.json();

      // console.log("fetch map data :", data)

      if (data.status === 'OK' && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { ...location, lat, lng };
      } else {
        console.error(`No results found for ${location.name}`);
        return { ...location, lat: null, lng: null };
      }
    } catch (error) {
      console.error(`Error fetching coordinates for ${location.name}:`, error);
      return { ...location, lat: null, lng: null };
    }
  };


  const fetchAllCoordinates = async () => {
    const coordinates = await Promise.all(
      locations.map((location) => fetchCoordinates(location))
    );
    // console.log(coordinates);
    // Use the coordinates to display locations on the map or perform other tasks
    // For now, let's just log the coordinates
  };

  useEffect(() => {
    fetchAllCoordinates();
  }, []);


  return (
    <div style={{ position: 'relative', height: '600px', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyCEf_w2HrAMgYx9ppvpIe5GIWSJiffRQnU', // Replace with your Google Maps API key
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          // mapTypeId: 'satellite',
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
          streetViewControl: true,
          scaleControl: true,
          gestureHandling: 'greedy',
          styles: [
            {
              featureType: 'administrative',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#444444' }],
            },
            // Adjust styles for other features as needed
          ],
        }}

      >
        {districts.map((district, index) => (
          <LocationShow
            key={index}
            lat={district.lat}
            lng={district.lng}
            text={`${district.name} ${district.count}`}
            onClick={() => handleMarkerClick(district.name, district.count)}

            style={{
              position: 'absolute',
              zIndex: 1000, // Set a higher z-index than the map
              // Other marker styles...
            }}
          />
        ))}
      </GoogleMapReact>
      {showModal && (
        <ModalOverlay style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }} onClick={handleClose}>
          <Modal  style={{
        position: 'absolute',
        zIndex: 1000, // Set a higher z-index than the map
        // Other marker styles...
      }} message={'Alumni From '} data={selectedAlumni} onClose={handleClose} />
        </ModalOverlay>
      )}
    </div>
  );
};

export default Map;
