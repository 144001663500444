import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React , { useState, useRef,useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate , Link } from "react-router-dom";
import servername from "../constants";
export default function Event() {
    const [currentTheme, setCurrentTheme] = useTheme();
    const [eventdata, seteventdata] = useState([]);
    let navigate = useNavigate ();
    useEffect(() => {
        ////console.log(servername);
        if (!localStorage.getItem('login_key')) {
          // user is not authenticated
          navigate('/login');
        }
        
      });


      function eventlist() {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ key: "" }),
        };
        fetch(`${servername}/event_list`, requestOptions1)
          .then((response) => response.json())
          .then((data) => {
            seteventdata(data);
            // console.log(data);
           
          });
      }
    
      useEffect(() => {
        eventlist();
      }, []);


    return (
        <React.Fragment>
          <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
    
          <main className="mainclass" >

            <div className='row'>

            {eventdata.length > 0 && 
            
            eventdata.map((element, elementindex) => (
              <div className='col-md-3'>
              <div class="card" style={{width:"18rem"}}>
  
  <div class="card-body">
    <h5 class="card-title" style={{fontFamily: 'Oxanium'}}><strong>{element['E_title']}</strong> </h5>
    <p class="card-text" style={{fontFamily: 'Oxanium'}}>{element['E_disc']}</p>
    <Typography><strong> Date :</strong>{element['E_date']}</Typography>
  </div>
</div>
              </div>
            ))}


           
              {/* <div className='col-md-3'>
              <div class="card" style={{width:"18rem"}}>
  
  <div class="card-body">
    <h5 class="card-title" style={{fontFamily: 'Oxanium'}}><strong>Ambedkar Jayanti</strong> </h5>
    <p class="card-text" style={{fontFamily: 'Oxanium'}}>Ambedkar Jayanti or Bhim Jayanti is observed on 14 April to commemorate the memory of B. R. Ambedkar, Indian politician and social reformer. It marks Ambedkar's birthday who was born on 14 April 1891. His birthday is also referred to as 'Equality Day' by some in India.</p>
    <Typography><strong> Date :</strong> 14-03-2023 Friday</Typography>
  </div>
</div>
              </div>
              <div className='col-md-3'>
              <div class="card" style={{width:"18rem"}}>
  
  <div class="card-body">
    <h5 class="card-title" style={{fontFamily: 'Oxanium'}}><strong>Ambedkar Jayanti</strong> </h5>
    <p class="card-text" style={{fontFamily: 'Oxanium'}}>Ambedkar Jayanti or Bhim Jayanti is observed on 14 April to commemorate the memory of B. R. Ambedkar, Indian politician and social reformer. It marks Ambedkar's birthday who was born on 14 April 1891. His birthday is also referred to as 'Equality Day' by some in India.</p>
    <Typography><strong> Date :</strong> 14-03-2023 Friday</Typography>
  </div>
</div>
              </div>
              <div className='col-md-3'>
              <div class="card" style={{width:"18rem"}}>
  
  <div class="card-body">
    <h5 class="card-title" style={{fontFamily: 'Oxanium'}}><strong>Ambedkar Jayanti</strong> </h5>
    <p class="card-text" style={{fontFamily: 'Oxanium'}}>Ambedkar Jayanti or Bhim Jayanti is observed on 14 April to commemorate the memory of B. R. Ambedkar, Indian politician and social reformer. It marks Ambedkar's birthday who was born on 14 April 1891. His birthday is also referred to as 'Equality Day' by some in India.</p>
    <Typography><strong> Date :</strong> 14-03-2023 Friday</Typography>
  </div>
</div>
              </div> */}
            </div>
            
            </main>
            </React.Fragment>

    );    
}