//import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
//import TextField from '@mui/material/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Compress from "react-image-file-resizer";
import React, { useState, useRef, useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate, Link, useAsyncValue } from "react-router-dom";
import servername from "../constants";
import CountryStateCityForm from './getlocations';

export default function Register() {
  let navigate = useNavigate();
  const [currentTheme, setCurrentTheme] = useTheme();

  const [batch, setBatch] = useState('');

  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Passoutyear, setPassoutyear] = useState('');
  const [Linkdin, setLinkdin] = useState('');
  const [Education, setEducation] = useState('');
  const [Password, setPassword] = useState('');
  const [RePassword, setRePassword] = useState('');
  const [NativePlace, setNativePlace] = useState('');
  const [WorkingPlace, setWorkingPlace] = useState('');
  const [occupationvalue, setoccupation] = useState('');
  const [districtvalue, setDistrict] = useState('');
  const [Country, setCountry] = useState('');
  const [State, setState] = useState('');
  const [City, setCity] = useState('');

  const [nameerror, setnameerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [numerror, setnumerror] = useState(false);
  const [occupationerror, setoccupationerror] = useState(false);
  const [educationerror, seteducationerror] = useState(false);
  const [batcherror, setbatcherror] = useState(false);
  const [loadopen, setloadopen] = useState(false);
  const [alertopen, setalertopen] = useState(false);
  const [erroralertopen, seterroralertopen] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [repassworderror, setrepassworderror] = useState(false);
  const [passwordmatch, setpasswordmatch] = useState(false);
  const [imagezie, setimagezie] = useState(false);
  const [imageload, setimageload] = useState(false);
  const [profileimage, setprofileimage] = useState();
  const [districterror, setdistricterror] = useState();
  const [occupationerro, setoccupationerro] = useState();
  const [erroryear, seterroryear] = useState(false);

  // const options = ['1987-1988 -  1st','1988-1989 - 2nd','1989-1990 - 3rd','1990-1991 - 4th','1991-1992 - 5th','1992-1993 - 6th','1993-1994 - 7th','1994-1995 - 8th','1995-1996 - 9th','1996-1997 - 10th','1997-1998 - 11th','1998-1999 - 12th','1999-2000 - 13th','2000-2001 - 14th','2001-2002 - 15th','2002-2003 - 16th','2003-2004 - 17th','2004-2005 - 18th','2005-2006 - 19th','2006-2007 - 20th','2007-2008 - 21st','2008-2009 - 22nd','2009-2010 - 23rd','2010-2011 - 24th','2011-2012 - 25th','2012-2013 - 26th','2013-2014 - 27th','2014-2015 - 28th','2015-2016 - 29th','2016-2017 - 30th','2017-2018 - 31st','2018-2019 - 32nd','2019-2020 - 33th','2020-2021 - 34th','2021-2022 - 35th','2022-2023 - 36th'
  //   ];

  const generateBatchOptions = (startYear, endYear) => {
    const batchOptions = [];
    for (let year = startYear; year <= endYear; year++) {
      batchOptions.push(`${year}-${year + 1}`);
    }
    return batchOptions;
  };
  const startYear = 1983;

  const currentYear = new Date().getFullYear();

  const batchoptions = generateBatchOptions(startYear, currentYear);
  const formattedBatchOptions = batchoptions.map((option, index) => ({
    label: `Batch ${index + 1}: ${option}`,
    value: option
  }));
  const yearRange = currentYear - startYear + 1;
  const yearoptions = Array.from({ length: yearRange }, (_, index) => (startYear + index).toString());

  console.log(yearoptions, batchoptions);

  // const batchoptions = ['1983-1984', '1984-1985', '1985-1986', '1986-1987', '1987-1988', '1988-1989', '1989-1990', '1990-1991', '1991-1992', '1992-1993', '1993-1994', '1994-1995', '1995-1996', '1996-1997', '1997-1998', '1998-1999', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023'
  // ];
  //   const yearoptions = ['1983','1984', '1984','1985', '1985','1986', '1986','1987', '1987','1988', '1988','1989', '1989','1990', '1990','1991', '1991','1992', '1992','1993', '1993','1994', '1994','1995', '1995','1996', '1996','1997', '1997','1998', '1998','1999', '1999','2000', '2000','2001', '2001','2002', '2002','2003', '2003','2004', '2004','2005', '2005','2006', '2006','2007', '2007','2008', '2008','2009', '2009','2010', '2010','2011', '2011','2012', '2012','2013', '2013','2014', '2014','2015', '2015','2016', '2016','2017', '2017','2018', '2018','2019', '2019','2020', '2020','2021', '2021','2022', '2022','2023'
  // ];

  const district = ["Bagalkot", "Ballari", "Belagavi", "Bengaluru Rural", "Bengaluru Urban",
    "Bidar", "Chamarajanagar", "Chikkaballapur", "Chikkamagaluru", "Chitradurga", "Dakshina Kannada",
    "Davanagere", "Dharwad", "Gadag", "Hassan", "Haveri", "Kalaburagi", "Kodagu", "Kolar",
    "Koppal", "Mandya", "Mysuru", "Raichur", "Ramanagara", "Shivamogga", "Tumakuru",
    "Udupi", "Uttara Kannada", "Vijayapura", "Yadgir"]

  const occupation = ["Engineer", "Doctor", "IAS", "KAS", "Police", "LIC", "Business", "professor", "Teacher", "Others"]

  function imagesize(p_image) {
    //////console.log(p_image.size);
    if (p_image.size > 50000000) {
      setimagezie(true);
    } else {
      setimagezie(false);
    }
  }


  function submitform1() {
    var url;
    var filename = profileimage.name;
    //////console.log(profileimage);
    var reader = new FileReader();

    reader.onload = function (e) {
      //////console.log(e.target.result);

      Compress.imageFileResizer(
        profileimage, // the file from input
        480, // width
        480, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        90, // quality
        0, // rotation
        (uri) => {
          //////console.log(uri);
          // You upload logic goes here
        },
        "base64" // blob or base64 default base64
      );



      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: e.target.result, filename: filename })
      };
      fetch(`${servername}/profile_image_upload`, requestOptions)
        .then(response => response.json())
        .then((data) => {

          //////console.log(data);



        })
        .catch(error => {
          throw (error);
        });
      //  navigator.clipboard.writeText(e.target.result);
    }

    reader.readAsDataURL(profileimage);

  }
  function submitform() {
    alert("called")
    //console.log('batch', districtvalue);
    if (Name == '') {
      setnameerror(true);
    }
    if (Number == '') {
      setnumerror(true);
    }
    if (Email == '') {
      setemailerror(true);
    }
    // if (Occupation == '') {
    //   setoccupationerror(true);
    // }
    if (Education == '') {
      seteducationerror(true);
    }
    if (batch == '') {
      setbatcherror(true);
    }
    if (Passoutyear == '') {
      seterroryear(true);
    }
    if (Password == '') {
      setpassworderror(true);
    }
    if (RePassword == '') {
      setrepassworderror(true);
    }
    if (districtvalue == '') {
      setdistricterror(true);
    }
    if (occupationvalue == '') {
      alert("password")
      setoccupationerro(true);
    }
    else {
      alert("else")
      if (Name != '' && Email != '' && Education != '' && batch != '' && Password != '' && RePassword != '' && Number != '' && districtvalue != '' && occupationvalue != '') {
        if (Password == RePassword) {
          setloadopen(true);
          alert("password")
          console.log( JSON.stringify({ A_Name: Name.trim(), A_Number: Number, A_Email: Email, A_Passoutyear: Passoutyear, A_Occupation: Occupation.trim(), A_Batch: batch, A_Education: Education.trim(), A_Linkdin: Linkdin, A_NativePlace: NativePlace.trim(), A_WorkingPlace: WorkingPlace.trim(), A_Password: Password, A_profile: data, A_Ditsrict: districtvalue, A_OccupationType: occupationvalue })
          )
          if (imageload) {
            alert("imgg")
            var filename = profileimage.name;
            //////console.log(profileimage);
            var reader = new FileReader();
            
            reader.onload = function (e) {
              Compress.imageFileResizer(
                profileimage, // the file from input
                100, // width
                100, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                100, // quality
                0, // rotation
                (uri) => {
                  //////console.log(uri);
                  const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ url: uri, filename: filename })
                  };
                  fetch(`${servername}/profile_image_upload`, requestOptions)
                    .then(response => response.json())
                    .then((data) => {

                      //////console.log(data);
                      const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ A_Name: Name.trim(), A_Number: Number, A_Email: Email, A_Passoutyear: Passoutyear, A_Occupation: Occupation.trim(), A_Batch: batch, A_Education: Education.trim(), A_Linkdin: Linkdin, A_NativePlace: NativePlace.trim(), A_WorkingPlace: WorkingPlace.trim(), A_Password: Password, A_profile: data, A_Ditsrict: districtvalue, A_OccupationType: occupationvalue ,Country:selectedCountry,State:selectedState,City:selectedCity})
                      };
                      fetch(`${servername}/insert_alumni`, requestOptions)
                        .then(response => response.json())
                        .then((data) => {
                          if (data['document']['acknowledged']) {
                            setloadopen(false);
                            setalertopen(true);

                          } else {
                            seterroralertopen(true);
                          }
                          //////console.log(data);

                          //alert.show('Oh look, an alert!')
                          //alert("successfully You have registerd");

                        })
                        .catch(error => {
                          throw (error);
                        });


                    })
                    .catch(error => {
                      throw (error);
                    });
                  // You upload logic goes here
                },
                "base64" // blob or base64 default base64
              );
              // //////console.log(e.target.result);

              //  navigator.clipboard.writeText(e.target.result);
            }

            reader.readAsDataURL(profileimage);




          } else {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ A_Name: Name.trim(), A_Number: Number, A_Email: Email, A_Passoutyear: Passoutyear, A_Occupation: Occupation.trim(), A_Batch: batch, A_Education: Education.trim(), A_Linkdin: Linkdin, A_NativePlace: NativePlace.trim(), A_WorkingPlace: WorkingPlace.trim(), A_Password: Password, A_Ditsrict: districtvalue, A_OccupationType: occupationvalue ,Country:selectedCountry,State:selectedState,City:selectedCity})
            };
            fetch(`${servername}/insert_alumni`, requestOptions)
              .then(response => response.json())
              .then((data) => {
                if (data['document']['acknowledged']) {
                  setloadopen(false);
                  setalertopen(true);

                } else {
                  seterroralertopen(true);
                }
                //////console.log(data);

                //alert.show('Oh look, an alert!')
                //alert("successfully You have registerd");

              })
              .catch(error => {
                throw (error);
              });
          }

          // //////console.log(Name);
        } else {
          setpasswordmatch(true);
        }
      }

      else {
        return false;
      }
    }


  }
  const handleClickToOpen = () => {
    setalertopen(true);
  };

  const handleToClose = () => {
    setalertopen(false);
    seterroralertopen(false);
  }
  const handleToClose1 = () => {
    navigate('/login');
    window.location.reload();
  }

  function data() {
    navigate('/login');
  }

///////////////
const [countries, setCountries] = useState([]);
const [states, setStates] = useState([]);
const [cities, setCities] = useState([]);
const [selectedCountry, setSelectedCountry] = useState('');
const [selectedState, setSelectedState] = useState('');
const [selectedCity, setSelectedCity] = useState('');

const config = {
  cUrl: 'https://api.countrystatecity.in/v1/countries',
  ckey: 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==',
};

// useEffect(() => {
//   // Load countries when the component mounts
//   fetch(config.cUrl, {
//     headers: {
//       'X-CSCAPI-KEY': config.ckey,
//     },
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       setCountries(data);
//     })
//     .catch((error) => console.error('Error loading countries:', error));
// }, []);

// useEffect(() => {
//   // Load states when a country is selected
//   if (selectedCountry) {
//     fetch(`https://api.countrystatecity.in/v1/countries/${selectedCountry}/states`, {
//       headers: {
//         'X-CSCAPI-KEY': config.ckey,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setStates(data);
//       })
//       .catch((error) => console.error('Error loading states:', error));
//   }
// }, [selectedCountry]);

// useEffect(() => {
//   // Load cities when a state is selected
//   if (selectedState) {
//     fetch(
//       `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${selectedState}/cities`,
//       {
//         headers: {
//           'X-CSCAPI-KEY': config.ckey,
//         },
//       }
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         setCities(data);
//       })
//       .catch((error) => console.error('Error loading cities:', error));
//   }
// }, [selectedState]);

/////////
  return (

    <React.Fragment>
      <Appbar
        currentTheme={currentTheme}
        setCurrentTheme={setCurrentTheme} />

      <main className='mainclass'>
        <center>
          <div style={{ backgroundColor: 'rgb(252 252 252)', padding: '50px', borderRadius: '30px' }} className="MainDiv"  >


            <div>
              <Dialog open={alertopen} onClose={handleToClose}>
                <DialogTitle style={{ color: 'green' }}>Success</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ color: 'black' }}>
                    Your registration request has been forwarded to the JNV Authority. You will receive a response via email once it has been reviewed and approved by the administrator..
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleToClose1}
                    color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>


              <Dialog open={erroralertopen} onClose={handleToClose}>
                <DialogTitle style={{ color: 'red' }}>Alert!!</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ color: 'black' }}>
                    Alumni Name Already Exists
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleToClose}
                    color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

              {loadopen && <div className="loader1" ></div>}

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography style={{ fontSize: '24px' }}>Alumni Registration Form</Typography>
                </Grid>

                {/* <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <div className="input-group mb-3">
          <Typography style={{ fontSize: '18px' }}>Select Country:</Typography>
          <Dropdown
            className="Dropdown-root2"
            options={countries.map((country) => ({
              value: country.iso2,
              label: country.name,
            }))}
            onChange={(option) => setSelectedCountry(option.value)}
            value={selectedCountry}
            placeholder="Select Country"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className="input-group mb-3">
          <Typography style={{ fontSize: '18px' }}>Select State:</Typography>
          <Dropdown
            className="Dropdown-root2"
            options={states.map((state) => ({
              value: state.iso2,
              label: state.name,
            }))}
            onChange={(option) => setSelectedState(option.value)}
            value={selectedState}
            placeholder="Select State"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className="input-group mb-3">
          <Typography style={{ fontSize: '18px' }}>Select City:</Typography>
          <Dropdown
            className="Dropdown-root2"
            options={cities.map((city) => ({
              value: city.iso2,
              label: city.name,
            }))}
            onChange={(option) => setSelectedCity(option.value)}
            value={selectedCity}
            placeholder="Select City"
          />
        </div>
      </Grid>
    </Grid> */}
            
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="fullName"
                    // className={classes.textField}
                    name="FullName"
                    label="FullName"
                    fullWidth
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    //variant="outlined"
                    variant="standard"
                    onBlur={(value) => { setName(value.target.value); setnameerror(false); }}

                  />
                  {nameerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Name Can't be blank!!!</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField

                    required
                    id="mobileNumber"
                    name="Mobile Number"
                    label="Mobile Number"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    type='number'
                    onBlur={(value) => { setNumber(value.target.value); setnumerror(false); }}
                  />
                  {numerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Number Can't be blank!!!</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField

                    required
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    type='email'
                    onBlur={(value) => { setEmail(value.target.value); setemailerror(false) }}
                  />
                  {emailerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Email Can't be blank!!!</Typography>}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
          <TextField
         
           // required
            id="pass_out_year"
            name="pass_out_year"
            label="Pass Out Year"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value)=>setPassoutyear(value.target.value)}
          />
        </Grid> */}
                <Grid item xs={12} sm={6} >


                  <div className="row gridclass">
                    {/* <div className="col-6" style={{paddingLeft: '0px' ,    marginLeft: '-10px'}}>
              <Typography style={{fontSize:'18px'}}>Select Passoutyear:</Typography></div> */}
                    <div className="col-6"><Dropdown className='Dropdown-root2' options={yearoptions} onChange={(value) => { setPassoutyear(value['value']); seterroryear(false) }} value={'Passout Year'} placeholder="Select Passout Year" /></div>
                  </div>
                  {/* {seterroryear && <Typography style={{color:'red',textAlign:'left'}}>Year Can't be blank!!!</Typography>} */}

                </Grid>
                <Grid item xs={12} sm={6} >


                  <div className="row gridclass">
                    {/* <div className="col-6" style={{paddingLeft: '0px' ,    marginLeft: '-10px'}}><Typography style={{fontSize:'18px'}}>Select Batch:</Typography></div> */}
                    <div className="col-6"> <Dropdown
          className='Dropdown-root2'
          options={formattedBatchOptions}
          onChange={(value) => { setBatch(value['value']); setbatcherror(false) }}
          value={batch || 'Batch'}
          placeholder="Select Batch"
        /></div>
                  </div>
                  {batcherror && <Typography style={{ color: 'red', textAlign: 'left' }}>Batch Can't be blank!!!</Typography>}

                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField

                    required
                    id="occupation"
                    name="occupation"
                    label="Occupation"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => { setOccupation(value.target.value); setoccupationerror(false); }}
                  />
                  {occupationerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Occupation Can't be blank!!!</Typography>}
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <div className="input-group mb-3" >

                    <div className="row gridclass">
                      {/* <div className="col-3" style={{paddingLeft: '0px' ,    marginLeft: '-5px'}}><Typography style={{fontSize:'18px'}}>
District:</Typography></div> */}
                      <div className="col-6"><Dropdown className='Dropdown-root2' options={district} onChange={(value) => { setDistrict(value['value']); setdistricterror(false) }} value={'District'} placeholder="Select District" /></div>
                    </div>
                    {/* {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>} */}
                  </div>
                  {districterror && <Typography style={{ color: 'red', textAlign: 'left' }}>District Can't be blank!!!</Typography>}
                  {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}

                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="input-group mb-3" >

                    <div className="row gridclass">
                      {/* <div className="col-4" style={{paddingLeft: '0px' ,    marginLeft: '-5px'}}><Typography style={{fontSize:'18px'}}>
Occupation Type:</Typography></div> */}
                      <div className="col-6"><Dropdown className='Dropdown-root2' options={occupation} onChange={(value) => { setoccupation(value['value']); setoccupationerro(false) }} value={'Occupation'} placeholder="Select an option" /></div>
                    </div>
                    {/* {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>} */}
                  </div>
                  {occupationerro && <Typography style={{ color: 'red', textAlign: 'left' }}>Occupation Type Can't be blank!!!</Typography>}
                  {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}

                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    // rows={2}
                    // multiline
                    required
                    id="education"
                    name="Education"
                    label="Education"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => { setEducation(value.target.value); seteducationerror(false) }}
                  />
                  {educationerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Education Can't be blank!!!</Typography>}
                </Grid><Grid item xs={12} sm={6}>
                  <TextField

                    //required
                    id="linkdin_profile"
                    name="Linkdin_profile"
                    label="Linkdin Profile"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => setLinkdin(value.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField

                    // required
                    id="native_place"
                    name="native_place"
                    label="Native Place"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => setNativePlace(value.target.value)}
                  />
                  {/* {educationerror && <Typography style={{color:'red',textAlign:'left'}}>Education Can't be blank!!!</Typography>} */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField

                    //required
                    id="working_Place"
                    name="working_Place"
                    label="Working Place"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => setWorkingPlace(value.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type='password'
                    required
                    id="Password"
                    name="Password"
                    label="Password"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => { setPassword(value.target.value); setpassworderror(false); }}
                  />
                  {passworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Can't be blank!!!</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type='password'
                    required
                    id="re-Password"
                    name="re-Password"
                    label="Re-Password"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onBlur={(value) => { setRePassword(value.target.value); setrepassworderror(false); }}
                  />
                  {passwordmatch && <Typography style={{ color: 'red', textAlign: 'left' }}>Password Doesn't Match !!!</Typography>}
                  {repassworderror && <Typography style={{ color: 'red', textAlign: 'left' }}>Re-Password Can't be blank!!!</Typography>}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="input-group mb-4" >
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1" style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}><Typography>Profile Image</Typography></span>
                    </div>
                    <input accept="image/*" type="file" className="form-control" style={{ paddingTop: '4px', paddingLeft: '5px' }} placeholder="profileimage" id="profileimage" aria-label="Username" aria-describedby="basic-addon1" onChange={(value) => { setprofileimage(value.target.files[0]); imagesize(value.target.files[0]); setimageload(true); }} />
                  </div>
                  {imagezie && <Typography style={{ color: 'red', textAlign: 'left' }}>Image Size Shoudn't be morethen 50kb!!</Typography>}
                  {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}

                </Grid>
                <Grid item xs={12} sm={12}>
                  <button className="btn btn-primary" style={{
                    width: '230px',
                    marginTop: '20px'
                  }} onClick={submitform}><Typography>Submit Form</Typography></button>
                </Grid>
              </Grid>
            </div>

          </div>
        </center>
      </main>

    </React.Fragment>
  );
}