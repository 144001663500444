
//import * as React from 'react';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';

// import Appbar from '../appbar';

// import 'react-dropdown/style.css';

// import React, { useState, useRef, useEffect } from "react";

// const FeedbackForm = ({ onChange }) => {
//     return (

//         <React.Fragment>
//           <Appbar/>
    
//           <main className='mainclass'>
//             <center>
//               <div style={{ backgroundColor: 'rgb(252 252 252)', padding: '50px', borderRadius: '30px' }} className="MainDiv"  >
    
    
//                 <div>
             
    
    
//                   {/* {loadopen && <div className="loader1" ></div>} */}
    
//                   <Grid container spacing={3}>
//                     <Grid item xs={12} sm={12}>
//                       <Typography style={{ fontSize: '24px' }}>Feedback Form</Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                   <TextField
//                     required
//                     id="FeedbackMessage"
//                     // className={classes.textField}
//                     name="FeedbackMessage"
//                     label="FeedbackMessage"
//                     fullWidth
//                     // inputProps={{
//                     //   autoComplete: 'off'
//                     // }}
//                     // //variant="outlined"
//                     // variant="standard"
//                     // onBlur={(value) => { setName(value.target.value); setnameerror(false); }}

//                   />
//                   {/* {nameerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Name Can't be blank!!!</Typography>} */}
//                 </Grid>
//                     <Grid item xs={12} sm={12}>
//                       <button className="btn btn-primary" style={{
//                         width: '230px',
//                         marginTop: '20px'
//                       }}><Typography>Submit Form</Typography></button>
//                     </Grid>
//                   </Grid>
//                 </div>
    
//               </div>
//             </center>
//           </main>
    
//         </React.Fragment>
//       );
// };

// export default FeedbackForm;


import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React , { useState, useRef,useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate , Link } from "react-router-dom";
import servername from "../constants";
import { TextareaAutosize } from '@material-ui/core';
export default function FeedbackForm() {
    const [currentTheme, setCurrentTheme] = useTheme();
    const [eventform, seteventform] = useState(true);
    const [maindiv, setmaindiv] = useState(true);
    const [Title,setTitle] = useState('');
    const [Discription,setDiscription] = useState('');
    const [titleerror,settitleerror] = useState(false);
    const [derror,setdrror] = useState(false);
    const [e_date , setDate] = useState();
    const [ederror,setedrror] = useState(false); 
    useEffect(() => {
      
    }, []);

    function addEvent(){
      seteventform(false);
      setmaindiv(false);
     }

    function addEventlist(){
      // seteventform(true);
      // setmaindiv(false);

      if(e_date == null){
        setedrror(true);
      }
      if(Title == ""){
        settitleerror(true);
      }
      if(Discription == ""){
        setdrror(true);
      }
      if(e_date != null && Title != "" && Discription != ""){
        const requestOptions= {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({E_title:Title, E_disc:Discription, E_date : e_date})
      };
      fetch(`${servername}/create_event`, requestOptions)
          .then(response => response.json())
          .then((data)=>{
            //console.log(data);
            if(data['document']['acknowledged']){
  
              // setloadopen(false);
              // setalertopen(true);
              
            }else{
              // seterroralertopen(true);
            }
           //////console.log(data);
          
           //alert.show('Oh look, an alert!')
           //alert("successfully You have registerd");
         
          })
          .catch(error => {
            throw(error);
        });
      }
  
     

   
    }



    return (
        <React.Fragment>
          <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
    
          <main className="mainclass" >
          {/* <div><button className='btn btn-secondary' onClick={addEvent}>Add Event</button></div> */}
          {eventform &&  <center>
            <div  style={{ backgroundColor: 'rgb(252 252 252)' , padding: '50px',borderRadius: '30px' }} className="MainDiv"  >
            <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
          <Typography style={{fontSize: '24px'}}>Feedback Form</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
          <TextField
         
            required
            id="Name"
            name="Your Name"
            label="Your Name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value)=>{setDiscription(value.target.value); setdrror(false);}}
          />
          {derror && <Typography style={{color:'red',textAlign:'left'}}>Name Can't be blank!!!</Typography>}
        </Grid>
          <Grid item xs={12} sm={12}>
          <TextareaAutosize
          aria-label="minimum height"
          minRows={5}
          placeholder="Write Your Feedback Here..."
          style={{ width: '100%' }}
            required
          //   id="Title"
          //   // className={classes.textField}
          //   name="Title"
          //   label="Event Title"
          //   fullWidth
          //   inputProps={{
          //     autoComplete: 'off'
          //  }}
           //variant="outlined"
            variant="standard"
             onBlur={(value)=>{setTitle(value.target.value); settitleerror(false);}}
            
          />
         {titleerror && <Typography style={{color:'red',textAlign:'left'}}>Message Can't be blank!!!</Typography>} 
        </Grid>
        {/* 
        <Grid item xs={12} sm={12}>
          <TextField
         
            required
            id="Date"
            name="Date"
            
            fullWidth
            autoComplete="given-name"
            variant="standard"
            type="datetime-local"
            onBlur={(value)=>{setDate(value.target.value); setdrror(false);}}
          />
          {ederror && <Typography style={{color:'red',textAlign:'left'}}>Date Can't be blank!!!</Typography>}
        </Grid> */}
        <Grid item xs={12} sm={12}>
         <button className="btn btn-primary" style={{width: '230px', 
    marginTop: '20px'}} onClick={addEventlist} ><Typography>Submit</Typography></button>
        </Grid>
         </Grid>
         </div>
            </center>}
          {maindiv &&  <div className='row'></div>}
            </main>
            </React.Fragment>

    );    
}

