import React, { useEffect } from 'react';

const Modal = ({ message ,data, onClose }) => {
    // Ensure data is available before rendering
    console.log("popup modal:", data)

    if (!data || !data.alumniDetails) {
        return null;
    }

    const { batch, alumniDetails } = data;

    useEffect(() => {
        // Prevent scrolling when the modal is opened
        document.body.style.overflow = 'hidden';

        return () => {
            // Restore scrolling when the modal is closed
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(211, 211, 211, 0.6)' }}>
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{message} : { `${batch}`}</h5>
              <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body" style={{ overflowX: 'auto' }}>
              <div className="table-responsive" style={{ maxHeight: '70vh' }}>
                <table className="table table-striped">
                  <thead className="sticky-top" style={{ backgroundColor: 'rgb(2, 2, 72)', color: "white" }}>
                    <tr>
                    <th style={{ borderRight: '0.5px solid white' }}>Sl.No</th> 
                      <th style={{ borderRight: '0.5px solid white' }}>Profile</th>
                      <th style={{ borderRight: '0.5px solid white' }}>Name</th>
                      <th style={{ borderRight: '0.5px solid white' }}>Email</th>
                      <th style={{ borderRight: '0.5px solid white' }}>Phone Number</th>
                      <th style={{ borderRight: '0.5px solid white' }}>Education</th>
                      <th style={{ borderRight: '0.5px solid white' }}>PassOut Year</th>
                      <th style={{ borderRight: '0.5px solid white' }}>Native Place</th>
                      <th>Working Place</th>
                      {/* Add more headers if needed */}
                    </tr>
                  </thead>
                  <tbody style={{ overflowY: 'auto' }}>
                    {alumniDetails.map((alumnus, index) => (
                      <tr key={alumnus._id}>
                        <td  style={{ borderRight: '0.5px solid #ccc' , alignItems:'center' , paddingLeft:'20px'}}>{index + 1}</td>
                       <td>
                        <div style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                        }}>
                          {alumnus.A_profile ? (
                            <img
                            // src="https://media.licdn.com/dms/image/D5603AQH2E2iLALl5vw/profile-displayphoto-shrink_800_800/0/1695904842198?e=1709769600&v=beta&t=Et--zZWmQoxVJ-W5GvG6aK5ym0YQ6VeXbuIVNPafpEA"

                              src={`https://jnvalumniassociation.co.in/public/images/${alumnus.A_profile}`}
                              alt="alumni"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (

                            <img
                            // src="https://media.licdn.com/dms/image/D5603AQH2E2iLALl5vw/profile-displayphoto-shrink_800_800/0/1695904842198?e=1709769600&v=beta&t=Et--zZWmQoxVJ-W5GvG6aK5ym0YQ6VeXbuIVNPafpEA"

                              src={"https://demofree.sirv.com/nope-not-here.jpg"}
                              alt="placeholder"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                      </td>

                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_Name ? alumnus.A_Name : "No Data"}</td>
                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_Email ? alumnus.A_Email : "No Data"}</td>
                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_Number ? alumnus.A_Number : "No Data"}</td>
                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_Education ? alumnus.A_Education : "No Data"}</td>
                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_Passoutyear ? alumnus.A_Passoutyear : "No Data"}</td>
                        <td style={{ borderRight: '0.5px solid #ccc' }}>{alumnus.A_NativePlace ? alumnus.A_NativePlace : "No Data"}</td>
                        <td>{alumnus.A_WorkingPlace ? alumnus.A_WorkingPlace : "No Data"}</td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );


};

export default Modal;
