
//import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Appbar from '../appbar';

import 'react-dropdown/style.css';

import React, { useState, useRef, useEffect } from "react";

const Donation = ({ onChange }) => {
    return (

        <React.Fragment>
          <Appbar/>
    
          <main className='mainclass'>
            <center>
              <div style={{ backgroundColor: 'rgb(252 252 252)', padding: '50px', borderRadius: '30px' }} className="MainDiv"  >
    
    
                <div>
             
    
    
                  {/* {loadopen && <div className="loader1" ></div>} */}
    
                  <Grid container spacing={3}>
                   <Grid item xs={12} sm={12}>
                      <Typography style={{ fontSize: '24px' }}>Donation Form</Typography>
                    </Grid>
                      {/*
                    <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="alumniname"
                    // className={classes.textField}
                    name="Alumni Name"
                    label="Alumni Name"
                    fullWidth
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    //variant="outlined"
                    variant="standard"
                    onBlur={(value) => { setName(value.target.value); setnameerror(false); }}

                  />
                  {nameerror && <Typography style={{ color: 'red', textAlign: 'left' }}>Name Can't be blank!!!</Typography>}
                </Grid> */}
                    {/* <Grid item xs={12} sm={12}>
                      <button className="btn btn-primary" style={{
                        width: '230px',
                        marginTop: '20px'
                      }}><Typography>Submit Form</Typography></button>
                    </Grid> */}
                  </Grid>
                </div>
    
              </div>
            </center>
          </main>
    
        </React.Fragment>
      );
};

export default Donation;
