import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import logo from './logo_2.png';
// import AdbIcon from '@material-ui/core/Adb';
import './App.css';
import classes from "./Header.module.scss";
import { useNavigate, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import servername from "./constants";
import Marquee from "react-fast-marquee";

const pages = ['Home', 'Alumnus', 'Register', 'Login'];

const settings = ['Profile', 'Logout'];




function Appbar() {
  const isMobile = window.innerWidth <= 768;
  let navigate = useNavigate();
  const pageslink = [
    homelink, Alumnuslink, registerlink, Contactlink
  ]

  function homelink() {
    navigate("/home");
  }
  function registerlink() {
    navigate("/register");
  }

  function Alumnuslink() {
    navigate("/alumnus");
  }

  function Contactlink() {
    navigate("/login");
  }

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  function Request() {
    navigate("/request");

  }
  // const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [count, setcount] = useState(false);
  const [eventdata, seteventdata] = useState([]);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    if (localStorage.getItem('type') == 'admin') {
      requestdata();
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function requestdata() {
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: "" }),
    };
    fetch(`${servername}/request_list`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //.log("length", data);
        if (data.length == 0) {
          setcount(data.length)

        } else {
          setcount(data.length)
        }
        //////.log("length", maindata.length);
      });
  }

  function eventlist() {
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: "" }),
    };
    fetch(`${servername}/event_list`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        seteventdata(data);
        //.log(data);

      });
  }

  useEffect(() => {
    eventlist();
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    alert('hi')
    setMenuOpen((p) => !p);

  };

  const ctaClickHandler = () => {
    menuToggleHandler();
    navigate("/page-cta");
  };


  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    //   <AppBar position="static" style={{backgroundColor:'#020248'}}>
    //   <Container maxWidth="xl">
    //     <Toolbar disableGutters>
    //       {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
    //       <Typography
    //         variant="h6"
    //         noWrap
    //         component="a"
    //         href="/"
    //         sx={{
    //           mr: 2,
    //           display: { xs: 'none', md: 'flex' },
    //           fontFamily: 'monospace',
    //           fontWeight: 700,
    //           letterSpacing: '.3rem',
    //           color: 'inherit',
    //           textDecoration: 'none',
    //         }}
    //         style={{color:'white'}}
    //       >
    //        <img src={logo} alt="Logo" style={{paddingRight:'10px'}} />

    //            नवोदय विद्यालय समिति


    //       </Typography>

    //       <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
    //         <IconButton
    //           size="large"
    //           aria-label="account of current user"
    //           aria-controls="menu-appbar"
    //           aria-haspopup="true"
    //           onClick={handleOpenNavMenu}
    //           color="inherit"
    //         >
    //           {/* <MenuIcon /> */}
    //         </IconButton>
    //         <Menu
    //           id="menu-appbar"
    //           anchorEl={anchorElNav}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'left',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'left',
    //           }}
    //           open={Boolean(anchorElNav)}
    //           onClose={handleCloseNavMenu}
    //           sx={{
    //             display: { xs: 'block', md: 'none' },
    //           }}
    //         >
    //           {/* {pages.map((page) => (
    //             <MenuItem key={page} onClick={handleCloseNavMenu}>
    //               <Typography   textAlign="center">{page}</Typography>
    //             </MenuItem>
    //           ))} */}
    //         </Menu> 
    //       </Box>
    //       {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
    //       {/* <Typography
    //         variant="h5"
    //         noWrap
    //         component="a"
    //         href=""
    //         sx={{
    //           mr: 2,
    //           display: { xs: 'flex', md: 'none' },
    //           flexGrow: 1,
    //           fontFamily: 'monospace',
    //           fontWeight: 700,
    //           letterSpacing: '.3rem',
    //           color: 'inherit',
    //           textDecoration: 'none',
    //         }}
    //       >
    //         LOGO
    //       </Typography> */}
    //       <Box style={{position: 'absolute', right:'100px', }} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
    //         {pages.map((page, i) => (
    //           <Button
    //           style={{ fontSize:'18px',color:'white', padding: '43px 60px'}}
    //             key={page}
    //           //   onClick={handleCloseNavMenu}
    //             sx={{ my: 2, color: 'white', display: 'block' }}
    //             onClick={pageslink[i]}
    //           >
    //             {page}

    //           </Button>
    //         ))}
    //       </Box>

    //       <Box sx={{ flexGrow: 0 }} style={{position: 'absolute', right:'0px'}}>
    //         <Tooltip title="Open settings">
    //           <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
    //             <Avatar  src="/static/images/avatar/2.jpg" />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           sx={{ mt: '45px' }}
    //           id="menu-appbar"
    //           anchorEl={anchorElUser}
    //           anchorOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //           open={Boolean(anchorElUser)}
    //           onClose={handleCloseUserMenu}
    //           style={{Top:'70px'}}
    //         >
    //           {settings.map((setting) => (
    //             <MenuItem key={setting} onClick={handleCloseUserMenu} >
    //               <Typography textAlign="center"  style={{ fontSize:'16px',color:'black'}}>{setting}</Typography>
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </Box>
    //     </Toolbar>
    //   </Container>
    // </AppBar>

    //   <header className={classes.header}>
    //   <div className={classes.header__content}>
    //       <Link to="/" className={classes.header__content__logo}>
    //       <img src={logo} alt="Logo" style={{paddingRight:'10px'}} />

    //       </Link>
    //       <nav
    //           className={`${classes.header__content__nav} ${
    //               menuOpen && size.width < 768 ? classes.isMenu : ""
    //           }`}
    //       >
    //           <ul>
    //               <li className='listclass'>
    //                   <Link to="/" onClick={menuToggleHandler}>
    //                    <Typography className="fontclass">Home</Typography>  
    //                   </Link>
    //               </li>
    //               <li className='listclass'>
    //                   <Link to="/alumnus" onClick={menuToggleHandler}>
    //                   <Typography className="fontclass">Alumnus</Typography>  

    //                   </Link>
    //               </li >
    //               <li className='listclass'>
    //                   <Link to="/register" onClick={menuToggleHandler}>
    //                   <Typography className="fontclass">Register</Typography>  

    //                   </Link>
    //               </li>
    //               <li className='listclass'>
    //                   <Link to="/" onClick={menuToggleHandler}>
    //                   <Typography className="fontclass">Contact</Typography>  

    //                   </Link>
    //               </li>
    //           </ul>

    //       </nav>
    //       <div className={classes.header__content__toggle}>
    //           {!menuOpen ? (
    //               <button onClick={menuToggleHandler} > <FaBars /></button>
    //           ) : (
    //               <button onClick={menuToggleHandler} ><FaBars /></button>
    //           )}
    //       </div>
    //   </div>
    // </header>
    <div>
      <Navbar collapseOnSelect expand="lg" variant="dark" className="navcustom" style={{ backgroundColor: '#020248' }}>
        {/* <Container> */}
        <Navbar.Brand href="/"><img src={logo} alt="Logo" className="logoim" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav " className="navbarmain">
          <Nav className="me-auto">
            <Nav.Link href="/" ><Typography className="navitem">Home</Typography> </Nav.Link>
            <Nav.Link href="/alumnus"><Typography className="navitem">Find Alumni</Typography></Nav.Link>
            <Nav.Link href="/register">
              <Typography className="navitem">Register</Typography></Nav.Link>
            <Nav.Link href="/gallery">
              <Typography className="navitem">Gallery</Typography></Nav.Link>
            <Nav.Link href="/events">
              <Typography className="navitem">Events</Typography></Nav.Link>
            <Nav.Link href="/gallery">
              <Typography className="navitem">Gallery</Typography></Nav.Link>
            {localStorage.getItem('type') == 'admin' && (
              <Nav.Link href="/request">

                <Typography className="navitem"><i class="fa fa-bell fa-lg" style={{ color: 'white' }} aria-hidden="true"></i><span className="notification-badge">{count}</span></Typography></Nav.Link>
            )}

            {!localStorage.getItem('login_key') && <Nav.Link eventKey={2} href="/login">
              <Typography className="navitem">Login</Typography>
            </Nav.Link>}
            {/* {localStorage.getItem('login_key') &&   <Nav.Link>  <button className="profile-btn dropdown-toggle" eventKey={2} href="/login" style={{marginRight: '40px',
    borderRadius: '50%',width: '50px', height: '50px', marginTop: '-10px'}} >
            <i class="fa fa-user fa-lg" style={{color: '#020248'}}aria-hidden="true"></i>
            {/* <Typography className="navitem"><i class="fa fa-user" aria-hidden="true"></i></Typography> 
            </button> 
           
            </Nav.Link>*/}
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            {localStorage.getItem('login_key') && <Dropdown style={{ marginRight: isMobile ? '40px' : '10px' }}>
              <Dropdown.Toggle style={{ backgroundColor: 'white', padding: '0rem 0.3rem' }} id="dropdown-basic"
              >
                {localStorage.getItem('p_image') === '' || localStorage.getItem('p_image') === null && <i class="fa fa-user fa-lg" style={{ color: '#020248' }} aria-hidden="true"></i>}
                {localStorage.getItem('p_image') !== '' && localStorage.getItem('p_image') !== null && <img src={`${servername}/public/images/${localStorage.getItem('p_image')}`} style={{ width: '100%', borderRadius: '50%' }} />}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/jobssettings">Career Jobs</Dropdown.Item>
                <Dropdown.Item href="/analytics">Analytics</Dropdown.Item>
                {/* <Dropdown.Item href="/locations">Locations</Dropdown.Item> */}
                {localStorage.getItem('type') == 'admin' && <Dropdown.Item href="/eventsetting">Events Settings</Dropdown.Item>}
                {localStorage.getItem('type') == 'admin' && <Dropdown.Item href="/request">Alumni Request</Dropdown.Item>}
                <Dropdown.Item href="/feedback">Feedback</Dropdown.Item>
                <Dropdown.Item href="/donate">Donate</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>}
          </Nav>
          <Nav>

          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}

      </Navbar>
      <div style={{ backgroundColor: '#9d71ab', height: '50px' }}>
        {eventdata.length > 0 && <Marquee>


          {eventdata.map((element, elementindex) => (
            <p style={{ marginLeft: 20, marginTop: 10 }}>Join us for a Grand Celebration of {element['E_title']} on {element['E_date']}</p>
          ))}

        </Marquee>}
        {eventdata.length == 0 && <Marquee>Welcome to JNV Alumni Association!!!</Marquee>}

      </div>
    </div>


  );
}

export default Appbar;
