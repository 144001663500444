import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React , { useState, useRef,useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate , Link } from "react-router-dom";
import servername from "../constants";
import Compress from "react-image-file-resizer";

export default function Alumni_Verification() {
    let navigate = useNavigate ();
    const [currentTheme, setCurrentTheme] = useTheme();
    const [data, setdata]= useState();
   
    useEffect(() => {
      ////console.log(servername);
      if (!localStorage.getItem('login_key')) {
        // user is not authenticated
        navigate('/login');
      }
   
      // getimages();
    });
    function alumnidata() {
     
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: window.location.href.split('=')[1] }),
      };
      fetch(`${servername}/alumni_list_data_verify`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          ////console.log(data);
          setdata(data);
          //////console.log("length", maindata.length);
        });
    }
   
    function verified () {
     
      const requestOptions1 = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ verified: true }),
      };
      fetch(`${servername}/alumni_verification/${window.location.href.split('=')[1]}`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          ////console.log(data);
          if(data['verified']){
            navigate('/alumnus');
          }
          //////console.log("length", maindata.length);
        });
    }

    useEffect(() => {
      alumnidata();
    }, []);



    const handleToClose = () => {
   
      seterroralertopen(false);
    }
   
    return (
        <React.Fragment>
          <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
    
          <main className="mainclass" >
          <center>
            {data && 
      <div  style={{ backgroundColor: 'rgb(252 252 252)' , padding: '50px',borderRadius: '30px' }} className="MainDiv"  >
         <Grid container spacing={3}>
 <Grid item xs={12} sm={12}>
  <Typography style={{fontSize: '24px'}}>Alumni Registration Details</Typography>
 </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Name:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>{data[0].A_Name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>Mobile Number:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>{data[0].A_Number}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
           
        <Typography>Email:</Typography>
     
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>{data[0].A_Email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>Batch:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography>{data[0].A_Batch}</Typography>
        </Grid>
        
        <Grid item xs={12} sm={12}>
         <button className="btn btn-primary" style={{width: '230px',
            marginTop: '20px'}}  onClick={()=>verified()}><Typography>Accept</Typography></button>
        </Grid>
    </Grid> 
        </div>
        }
        </center>
          </main>
          </React.Fragment>  
            );
             
}