import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import servername from "../constants";
const Submit = (props) => {
  const [occupation, setOccupation] = useState('');
  const [place, setPlace] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  useEffect(() => {
    const { steps } = props;
    //console.log(steps)
    // const { name, gender, age } = steps;

    // setOccupation(steps[2]['value']);
    // setPlace(steps[5]['value']);

    const requestOptions= {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ occupation:'LIC',place:'hubballi'})
  };
  fetch(`${servername}/find_data`, requestOptions)
      .then(response => response.json())
      .then((data)=>{
      
       //console.log(data);
       setEmail(data.document[0]['A_Email']);
    setNumber(data.document[0]['A_Number']);
    setName(data.document[0]['A_Name']);
       //alert.show('Oh look, an alert!')
       //alert("successfully You have registerd");
     
      })
      .catch(error => {
        throw(error);
    });


    // setAge(age);
  }, [props]);

  return (
    <div style={{ width: '100%' }}>
      <h5 style={{color:'black'}}>Name:</h5><h6>{name}</h6>
      <h5 style={{color:'black'}}>Email:</h5><h6>{email}</h6>
      <h5 style={{color:'black'}}>Number:</h5><h6>{number}</h6>
    
    </div>
  );
};

Submit.propTypes = {
  steps: PropTypes.object,
};

Submit.defaultProps = {
  steps: undefined,
};

export default Submit;
