//import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from "../theme";
import Appbar from "../appbar";
import Box from "@material-ui/core/Box";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useRef, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import servername from "../constants";
import Compress from "react-image-file-resizer";
import profile_im from "./user.png";
import whatsapp from "./whatsapp.png";
import viewmore from "./viewmore.png";
import ChatBot from 'react-simple-chatbot';
import bot from "./bot.png";
import { ThemeProvider } from 'styled-components';
import Submit from "./submit";
// import Form from './form';
import { FaBars, HiOutlineBarsArrowDown, FaUserEdit,FaArchive } from 'react-icons/fa';
export default function Alumnus (){
 
  let [showChat, setShowChat] = useState(false);

  const startChat = () => { setShowChat(true); }
  const hideChat = () => { setShowChat(false); }


  const [currentTheme, setCurrentTheme] = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loadopen, setLoadopen] = React.useState(true);

  const [maindata, setmainData] = useState([]);
  const [selectedAlumini, setSelectedAlumini] = useState(null);
  const [dalaavailable, setDalaAvailable] = useState(false);
  const [editeddata, setediteddata] = useState([]);
  const [maindiv, setmaindiv] = useState(true);
  const [editdiv, seteditdiv] = useState(false);

  const [batch, setBatch] = useState('');
 
  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Passoutyear, setPassoutyear] = useState('');
  const[Linkdin,setLinkdin]=useState('');
  const[Education,setEducation]=useState('');
  const [nameerror, setnameerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const[numerror, setnumerror] = useState(false);
  const[occupationerror, setoccupationerror] = useState(false);
  const[educationerror, seteducationerror] = useState(false);
  const[batcherror, setbatcherror] = useState(false);
  const[loadopen1, setloadopen] = useState(false);
  const[alertopen, setalertopen] = useState(false);
  const[erroralertopen, seterroralertopen] = useState(false);
  const[NativePlace,setNativePlace] = useState('');
  const[WorkingPlace,setWorkingPlace] = useState('');
  const[docid, setdocid] = useState('');
  const [s_data, setsdata] = useState('');
  const[sb_data, setsb_data] = useState('');
  const[searchdiv, setsearchdiv] = useState(false);
  const[imagezie, setimagezie] = useState(false);
  const[imageload, setimageload] = useState(false);
  const[profileimage,setprofileimage] = useState();
  const [refresh, setrefresh] = useState(false);
  const[districtvalue, setDistrict] = useState('');
  const [districterror, setdistricterror] =useState();
  const[occupationvalue, setoccupation] = useState('');
  const [occupationerro, setoccupationerro] =useState();
  let navigate = useNavigate ();
  const isMobile = window.innerWidth <= 768;
  const handleClickOpen = (index) => {
    setSelectedAlumini(index);
  };

  function imagesize(p_image){
    //////console.log(p_image.size);
    if(p_image.size > 50000000){
     setimagezie(true);
    }else{
     setimagezie(false);
    }
     }

  const EditData = (id) => {
    setdocid(id._id );
    seteditdiv(true);
    setmaindiv(false);
       //////console.log(id._id);
       setBatch(id.A_Batch);
       setName(id.A_Name);
       setEmail(id.A_Email);
       setOccupation(id.A_Occupation);
       setNativePlace(id.A_NativePlace);
       setWorkingPlace(id.A_WorkingPlace);
       setPassoutyear(id.A_Passoutyear);
       setEducation(id.A_Education);
       setNumber(id.A_Number);
       setLinkdin(id.A_Linkdin);
       setDistrict(id.A_Ditsrict);
       setoccupation(id.A_OccupationType);
       
      //  const requestOptions1 = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ id: id._id }),
      // };
      // fetch("${servername}/alumni_list_data", requestOptions1)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     //////console.log('data', data);
      //    });
  };
  const district = [  "Bagalkot", "Ballari", "Belagavi", "Bengaluru Rural", "Bengaluru Urban",
      "Bidar", "Chamarajanagar", "Chikkaballapur", "Chikkamagaluru", "Chitradurga", "Dakshina Kannada",
      "Davanagere", "Dharwad", "Gadag", "Hassan", "Haveri", "Kalaburagi", "Kodagu", "Kolar",
      "Koppal", "Mandya", "Mysuru", "Raichur", "Ramanagara", "Shivamogga", "Tumakuru",
      "Udupi", "Uttara Kannada", "Vijayapura", "Yadgir"]
      
      const occupation = [ "Engineer", "Doctor", "IAS", "KAS", "Police", "LIC", "Business", "professor", "Teacher" ]

    
      const defaultOption1 = 'Select District';
      const defaultOption2 = 'Select Type';
  useEffect(() => {
    //////console.log(servername);
    if (!localStorage.getItem('login_key')) {
      // user is not authenticated
      navigate('/login');
    }
    setOpen(true);
  }, [selectedAlumini]);

  function alumnidata() {
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: "" }),
    };
    fetch(`${servername}/alumni_list`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        ////console.log(data);
        setmainData(data);
        setLoadopen(false);
        setsearchdiv(true);
        if(data.length == 0){
          setDalaAvailable(true);
          setsearchdiv(false);
        }
        //////console.log("length", maindata.length);
      });
  }

  useEffect(() => {
    alumnidata();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedAlumini(null);
  };

  function deletealumni(id){
    var text = "Are you sure!!, You want to reject"
    if (confirm(text) == true) {
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: id._id }),
      };
      fetch(`${servername}/deletealumni`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          //console.log("length", data);
          requestdata();
          //////console.log("length", maindata.length);
        });
    } else {
      return false;
    }
   
   }
 
  function searchdata() {
    // alert(s_data);
    if(s_data != ''){
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ A_Name: s_data }),
    };
    fetch(`${servername}/alumni_list_searchdata`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //////console.log(data);
        setrefresh(true);
        setmainData(data);
        setLoadopen(false);
        if(data.length == 0){
          setDalaAvailable(true);
        }else{
          setDalaAvailable(false);

        }
        //////console.log("length", maindata.length);
      });
    }
  }

function searchdatawithnum(){
  if(s_data != ''){

  const requestOptions1 = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ A_Number: s_data }),
  };
  fetch(`${servername}/alumni_list_searchdatanum`, requestOptions1)
    .then((response) => response.json())
    .then((data) => {
      //////console.log(data);
      setrefresh(true);
      setmainData(data);
      setLoadopen(false);
      if(data.length == 0){
        setDalaAvailable(true);
      }else{
        setDalaAvailable(false);

      }
      //////console.log("length", maindata.length);
    });
  }
}


  const ClickHereToKnowMore = () => {
    navigate('/moredetails');
  };

  function searchdatabatch(data) {
    // alert(data);
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ A_Batch: data }),
    };
    fetch(`${servername}/alumni_list_searchdata_batch`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //////console.log(data);
        setrefresh(true);
        setmainData(data);
        setLoadopen(false);
        if(data.length == 0){
          setDalaAvailable(true);
        }else{
          setDalaAvailable(false);
        }
        //////console.log("length", maindata.length);
      });
  }



  const options = ['1983-1984','1984-1985','1985-1986','1986-1987','1987-1988','1988-1989','1989-1990','1990-1991','1991-1992','1992-1993','1993-1994','1994-1995','1995-1996','1996-1997','1997-1998','1998-1999','1999-2000','2000-2001','2001-2002','2002-2003','2003-2004','2004-2005','2005-2006','2006-2007','2007-2008','2008-2009','2009-2010','2010-2011','2011-2012','2012-2013','2013-2014','2014-2015','2015-2016','2016-2017','2017-2018','2018-2019','2019-2020','2020-2021','2021-2022','2022-2023'
];
      const defaultOption = batch;
   
      function submitform(){ 
        
        //////console.log('batch', docid);
        if(Name==''){
          setnameerror(true);
        }
        if(Number==''){
          setnumerror(true);
        }
        if(Email==''){
          setemailerror(true);
        }
        if(Occupation==''){
          setoccupationerror(true);
        }
        if(Education==''){
          seteducationerror(true);
        }
        if(batch==''){
          setbatcherror(true);
        }
        if(districtvalue==''){
          setdistricterror(true);
        }
        if(occupationvalue==''){
          setoccupationerro(true);
        }
       else{ 

        if(Name !=''&& Email !='' && Occupation!='' && Education !='' && batch!='' && districtvalue!='' && occupationvalue!=''){
          setloadopen(true);

          if(imageload){
            var filename = profileimage.name;
            //////console.log(profileimage);
            var reader = new FileReader();
    
            reader.onload = function (e) {
              Compress.imageFileResizer(
                profileimage, // the file from input
                100, // width
                100, // height
                "JPEG", // compress format WEBP, JPEG, PNG
                100, // quality
                0, // rotation
                (uri) => {
                  //////console.log(uri);
                  const requestOptions= {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({url:uri,filename:filename})
                };
                fetch(`${servername}/profile_image_upload`, requestOptions)
                    .then(response => response.json())
                    .then((data)=>{
                     
                     //////console.log(data);
                     const requestOptions= {
                      method: 'PUT',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ A_Name:Name.trim(),A_Number:Number,A_Email:Email,A_Passoutyear:Passoutyear,A_Occupation:Occupation.trim(),A_Batch:batch,A_Education:Education.trim(),A_Linkdin :Linkdin, A_NativePlace:NativePlace.trim() , A_WorkingPlace :WorkingPlace.trim(), A_profile:data, A_Ditsrict:districtvalue, A_OccupationType:occupationvalue})
                  };
                  fetch(`${servername}/alumni_list_data/${docid}`, requestOptions)
                  .then(response => response.json())
                  .then((data)=>{
                    if(data['lastErrorObject']['updatedExisting']){
                      setloadopen(false);
                      setalertopen(true);
                      
                    }else{
                      seterroralertopen(true);
                    }
                   //////console.log(data['lastErrorObject']['updatedExisting']);
                  
                   //alert.show('Oh look, an alert!')
                   //alert("successfully You have registerd");
                 
                  })
                      .catch(error => {
                        throw(error);
                    });
                   
                   
                    })
                    .catch(error => {
                      throw(error);
                  });
                  // You upload logic goes here
                },
                "base64" // blob or base64 default base64
              );
          // //////console.log(e.target.result);
         
          //  navigator.clipboard.writeText(e.target.result);
            }
      
         reader.readAsDataURL(profileimage);




          }else{
            const requestOptions= {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ A_Name:Name.trim(),A_Number:Number,A_Email:Email,A_Passoutyear:Passoutyear,A_Occupation:Occupation.trim(),A_Batch:batch,A_Education:Education.trim(),A_Linkdin :Linkdin, A_NativePlace:NativePlace.trim() , A_WorkingPlace :WorkingPlace.trim(), A_Ditsrict:districtvalue, A_OccupationType:occupationvalue})
          };
          fetch(`${servername}/alumni_list_data/${docid}`, requestOptions)
              .then(response => response.json())
              .then((data)=>{
                if(data['lastErrorObject']['updatedExisting']){
                  setloadopen(false);
                  setalertopen(true);
                  
                }else{
                  seterroralertopen(true);
                }
               //////console.log(data['lastErrorObject']['updatedExisting']);
              
               //alert.show('Oh look, an alert!')
               //alert("successfully You have registerd");
             
              })
              .catch(error => {
                throw(error);
            });
          }


        
           // //////console.log(Name);
        }
        
       else{
          return false;
        }
      }
      
     
      }

     function cancel(){
      setrefresh(false);
      window.location.reload();
     }

      const handleClickToOpen1 = () => {
        setalertopen(true);
      };
      
      const handleToClose1 = () => {
        setalertopen(false);
        seterroralertopen(false);
      }
      const CustomStep = ({ triggerNextStep }) => {
        return (
            <button onClick={() => triggerNextStep({ trigger: '2' })}>Next</button>
        );
    };
      const steps = [
        {
            id: '0',
            message: 'Hey Dear Alumni!',
            
            // This calls the next id
            // i.e. id 1 in this case
           
             trigger: '1',
        }, {
            id: '1',
     
            // This message appears in
            // the bot chat bubble
            // component: <CustomStep />,
            message: 'Please choose below option to get alumni details',
             trigger: '2'
        }, 
        {
          id: '2',
   
          // Here we want the user
          // to enter input
          // user: true,
          options: [
               
            // When we need to show a number of
            // options to choose we create alist
            // like this
            { value: 'Doctors', label: 'Doctors', trigger : 3 },
            { value: 'Engineers', label: 'Engineers' ,trigger :3},
            { value:  'Teachers' , label: 'Teachers' ,trigger :3},
            { value: 'IAS' , label: 'IAS' ,trigger :3},
            { value: 'Police', label: 'Police' ,trigger :3},
            { value: 'CA', label: 'CA' ,trigger :3},
            { value: 'Inscurence Ofiicers' , label: 'Inscurence Ofiicers' ,trigger :3},
            { value: 'Lic Ofiicers' , label: 'Lic Ofiicers' ,trigger :3},
 
        ],
          // trigger: '3',
      },
        {
          id: '3',
   
          // Here we want the user
          // to enter input
          // user: true,
          options: [
               
            // When we need to show a number of
            // options to choose we create alist
            // like this
            { value: 'North India', label: 'North Karnataka', trigger : 4  },
            { value: 'South India', label: 'South Karnataka' ,trigger :5}
          
 
        ],
          // trigger: '3',
      },
        
        {
            id: '4',
            // message: " hi {previousValue}, how can I help you?",
            // message: "'Please choose below option to get alumni details'",
            options: [
                 
              // When we need to show a number of
              // options to choose we create alist
              // like this
              { value: 'Bagalkote', label: 'Bagalkote', trigger : 6 },
              { value: 'Bijapur', label: 'Bijapur' ,trigger :6},
              { value: 'Gadag', label: 'Gadag' ,trigger :6},
              { value: 'Dharwad', label: 'Dharwad' ,trigger :6},
              { value: 'Haveri', label: 'Haveri' ,trigger :6},
              { value: 'Belagavi', label: 'Belagavi' ,trigger :6},
              { value: 'Bellary', label: 'Bellary' ,trigger :6},
              { value: 'Bidar', label: 'Bidar' ,trigger :6},
              { value: 'Kalaburagi', label: 'Kalaburagi' ,trigger :6}, 
               { value: 'Koppal', label: 'Koppal' ,trigger :6},
               { value: 'Raichur', label: 'Raichur' ,trigger :6},
              { value: 'BelVijayanagara', label: 'Vijayanagara ' ,trigger :6},
              { value: 'Yadgir', label: 'Yadgir ' ,trigger :6},

   
          ],
            
        },
        {
          id: '5',
          // message: " hi {previousValue}, how can I help you?",
          // message: "'Please choose below option to get alumni details'",
          options: [
               
            // When we need to show a number of
            // options to choose we create alist
            // like this
            { value: 'Bengaluru Urban', label: 'Bengaluru Urban', trigger : 6 },
            { value: 'Bengaluru Rural', label: 'Bengaluru Rural' ,trigger :6},
            { value: 'Chikkaballapur', label: 'Chikkaballapur' ,trigger :6},
            { value: 'Kolar', label: 'Kolar' ,trigger :6},
            { value: 'Mysuru', label: 'Mysuru' ,trigger :6},
            { value: 'Mandya', label: 'Mandya' ,trigger :6},
            { value: 'Kodagu', label: 'Kodagu' ,trigger :6},
            { value: 'Hassan', label: 'Hassan' ,trigger :6},
            { value: 'Chikmagalur', label: 'Chikmagalur' ,trigger :6}, 
             { value: 'Shivamogga', label: 'Shivamogga' ,trigger :6},
             { value: 'Tumakuru', label: 'Tumakuru' ,trigger :6},
            { value: 'Chitradurga', label: 'Chitradurga ' ,trigger :6},
            { value: 'Davanagere', label: 'Davanagere ' ,trigger :6},
            { value: 'Chamarajanagar', label: 'Chamarajanagar ' ,trigger :6},
            { value: 'Ramanagara', label: 'Ramanagara ' ,trigger :6}

 
        ],
          
      },  {
            id: '6',
            // message: "'You choosen {previousValue}'",
            component: <Submit />,
            // options: [
                 
            //     // When we need to show a number of
            //     // options to choose we create alist
            //     // like this
            //     { value: 1, label: 'View Courses', trigger :1 },
            //     { value: 2, label: 'Read Articles' ,trigger :2},
     
            // ],
            trigger: '7',
            // end: true
        },
        {
          id: '7',
           message: "Do You want to continue?",
           trigger: '8',
         
      },
      {
        id: '8',
     
      
        options: [
             
        //     // When we need to show a number of
        //     // options to choose we create alist
        //     // like this
            { value: 1, label: 'Yes', trigger :1 },
            { value: 2, label: 'No' ,trigger :9},
 
        ],
        // end: true
    },
      {
        id: '9',
         message: "Thank You.. Have a nice day",
      
      
        end: true
    }
    ];
     
    // Creating our own theme
    const theme = {
        background: '#BABAD0',
        headerBgColor: '#020248',
        headerFontSize: '20px',
        botBubbleColor: '#1C1C72',
        headerFontColor: 'white',
        botFontColor: 'white',
        userBubbleColor: '#42448C',
        userFontColor: 'white',
    };
     
    // Set some properties of the bot
    const config = {
        botAvatar: bot,
        floating: true,
    };


  // const options = [
  //   1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006,
  //   2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
  // ];
  // const defaultOption = "1995";
  return (
    <React.Fragment>
      <Appbar currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />

      <main className="mainclass" >
     
      {/* <Dialog
          open={loadopen}
          onClose={handleClose}
          style={{ width: "100% !important" }}
        > 
          <DialogContent style={{overflow: 'hidden'}}>
      <div className="loader" ></div>
      </DialogContent>
      </Dialog> */}
      {maindiv && (<div className="Container maindivmargin" >
       {searchdiv && ( <div className="row">
          <div className="col-md-5">
            {refresh && <button className="btn btn-secondary" onClick={cancel
            }><i class="fa fa-arrow-left" aria-hidden="true"></i></button>}
          </div>
          <div className="col-md-7">
          <div className="row">
          <div className="col-md-4">  <div class="input-group " style={{marginBottom:isMobile ? 20 :0}}>
            <input type="search" placeholder="Search with Name" aria-describedby="button-addon5" onChange={(value)=> setsdata(value.target.value)} class="form-control"/>
           
            <div class="input-group-append">
              <button id="button-addon5" type="submit" class="btn " style={{backgroundColor: '#020248',    color: 'white'}} onClick={searchdata}><i class="fa fa-search"></i></button>
            </div>
          </div></div>
          <div className="col-md-4">  <div class="input-group " >
            <input type="search" placeholder="Search with Number" aria-describedby="button-addon5" onChange={(value)=> setsdata(value.target.value)} class="form-control"/>
           
            <div class="input-group-append">
              <button id="button-addon5" type="submit" class="btn " style={{backgroundColor: '#020248',    color: 'white'}} onClick={searchdatawithnum}><i class="fa fa-search"></i></button>
            </div>
          </div></div>
          <div className="col-md-4 Dropdown-root1"><Dropdown  options={options} onChange={(value) => searchdatabatch(value['value'])}  value={defaultOption} placeholder="Filter Batch" /></div>
          </div>
        
          </div>
          
          </div> )}
     
      </div>)}
      
      {maindiv && (<div className="Container " >
        {loadopen &&  <div className="loader dataerror" style={{marginTop:'3%'}}></div>}
        

        {dalaavailable && <Typography className="message">Data Not Available</Typography>}
          <div className="row">
           
            
            {maindata.length > 0 && 
            
              maindata.map((element, elementindex) => (
               
                <div className="col-md-3">
                  <div className="cards" style={{maxHeight: '180px', minHeight: '180px'}}>
                    <div className="row" style={{paddingTop: '15px'}}>
                    <div className="col-3" style={{paddingTop: '10px'}}>
                      
                      { element["A_profile"] ===undefined && <img className="profileim" src={profile_im} alt="Logo" style={{paddingTop: '5px'}} />}
                      {element["A_profile"] === '' && <img className="profileim" src={profile_im} alt="Logo" style={{paddingTop: '5px'}} />}
                      {element["A_profile"] !== '' && element["A_profile"]!==undefined &&  <img className="profileim" src={'https://jnvalumniassociation.co.in/public/images/'+element["A_profile"]} alt="Logo"  />}
                   
                      </div>
                      <div className="col-9"> 
                      <Typography className="name">
                      {element["A_Name"]}
                    </Typography>
                    <Typography>{element["A_Number"]}</Typography>
                    <Typography>{element["A_Batch"]}</Typography>
                    <div style={{marginTop: '10px',textAlign:'center',marginRight:'-9px'}}>
                          {/* <img src={viewmore} alt="Logo" style={{ paddingTop: '0px', width: 50 }} /> */}
                       <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleClickOpen(elementindex)}
                      style={{backgroundColor: '#020248', color: 'white'}}
                    >
                     Know More
                    </Button>
                    
                    {(element["A_Email"] == localStorage.getItem('email') || localStorage.getItem('type') == 'admin') &&   <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>  EditData(element) }
                      style={{backgroundColor: '#5A5A7C', color: 'white','marginLeft': '10px'  }}
                    >
                      Edit
                      {/* <FaUserEdit /> */}
                    </Button>}
                    
                    {/* {(element["A_Email"] == localStorage.getItem('email') || localStorage.getItem('type') == 'admin') &&   <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>  deletealumni(element) }
                      style={{backgroundColor: '#5A5A7C', color: 'white','marginLeft': '10px','height':'37px'}}
                    > Delete
                    </Button>} */}
                    
                    {(element["A_Email"] != localStorage.getItem('email') && localStorage.getItem('type') != 'admin') && 
                   <a href={`https://api.whatsapp.com/send/?phone=${element['A_Number']}&text=hi`}  target="_blank"><img  src={whatsapp} alt="Logo" style={{paddingTop: '0px', width:50}}/></a>  }
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleClickOpen(elementindex)}
                      style={{backgroundColor: 'gray', color: 'white','marginLeft': '10px'}}
                    >
                    
                    
                    </Button> */}
                    
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleClickOpen(elementindex)}
                      style={{backgroundColor: 'gray', color: 'white','marginLeft': '10px', display:'none'}}
                    >
                      Delete
               
                    </Button> */}
                    </div>
                    </div>
                 </div>
                   
                  </div>
                </div>
              ))}
          </div>
        </div>)}
        
        {/* Edit div  */}
        {editdiv && (
           <center>
           <div  style={{ backgroundColor: 'rgb(252 252 252)' , padding: '50px',borderRadius: '30px' }} className="MainDiv"  >
          
           
          <div>
           <Dialog open={alertopen} onClose={handleToClose1}>
                 <DialogTitle style={{color:'green'}}>Success</DialogTitle>
                 <DialogContent>
                   <DialogContentText style={{color:'black'}}>
                    You Have updated successfully 
                   </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                   <Button onClick={handleToClose1} 
                           color="primary" autoFocus>
                     Close
                   </Button>
                 </DialogActions>
               </Dialog>
         
         
               <Dialog open={erroralertopen} onClose={handleToClose1}>
                 <DialogTitle style={{color:'red'}}>Alert!!</DialogTitle>
                 <DialogContent>
                   <DialogContentText style={{color:'black'}}>
                    Data not updated !!!
                   </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                   <Button onClick={handleToClose1} 
                           color="primary" autoFocus>
                     Close
                   </Button>
                 </DialogActions>
               </Dialog>
         
          {loadopen1 && <div className="loader1" ></div>  }
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
           <Typography style={{fontSize: '24px'}}>Edit Alumni Data</Typography>
          </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                     required
                     id="fullname"
                     name="FullName"
                     label="FullName"
                     value={Name}
                     fullWidth
                     inputProps={{
                       autoComplete: 'off'
                    }}
                     variant="standard"
                     onChange={(value)=>setName(value.target.value)}
                     
                   />
                  {nameerror && <Typography style={{color:'red',textAlign:'left'}}>Name Can't be blank!!!</Typography>} 
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="mobileNumber"
                     name="Mobile Number"
                     label="Mobile Number"
                     value={Number}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setNumber(value.target.value)}
                   />
                   {numerror && <Typography style={{color:'red',textAlign:'left'}}>Number Can't be blank!!!</Typography>}
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="email"
                     name="email"
                     label="Email"
                     value={Email}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setEmail(value.target.value)}
                   />
                   {emailerror && <Typography style={{color:'red',textAlign:'left'}}>Email Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                    // required
                     id="pass_out_year"
                     name="pass_out_year"
                     label="Pass Out Year"
                     value={Passoutyear}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setPassoutyear(value.target.value)}
                   />
                   {/* <Typography style={{color:'red',textAlign:'left'}}>Name Can't be blank!!!</Typography> */}
                 </Grid><Grid item xs={12} sm={6} >
                    
                
                  <div className="row gridclass">
                   <div className="col-6" style={{paddingLeft: '0px' ,    marginLeft: '-10px'}}><Typography style={{fontSize:'18px'}}>Select Batch:</Typography></div>
                   <div className="col-6"><Dropdown options={options} onChange={(value)=>setBatch(value['value'])}  value={defaultOption} placeholder="Select an option" /></div>
                   </div>   
                   {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>}
              
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="occupation"
                     name="occupation"
                     label="Occupation"
                     value={Occupation}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setOccupation(value.target.value)}
                   />
                   {occupationerror && <Typography style={{color:'red',textAlign:'left'}}>Occupation Can't be blank!!!</Typography>}
                 </Grid>
                 <Grid item xs={12} sm={6}>
        <div className="input-group mb-3" >

  <div className="row gridclass">
          <div className="col-3" style={{paddingLeft: '0px' ,    marginLeft: '-5px'}}><Typography style={{fontSize:'18px'}}>
District:</Typography></div>
          <div className="col-6"><Dropdown className='Dropdown-root2' options={district} onChange={(value)=>{setDistrict(value['value']); setdistricterror(false)}}  value={districtvalue} placeholder="Select an option" /></div>
          </div>   
          {/* {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>} */}
       </div>
       {districterror && <Typography style={{color:'red',textAlign:'left'}}>District Can't be blank!!!</Typography>}
          {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}
        
        </Grid>
        <Grid item xs={12} sm={6}>
        <div className="input-group mb-3" >

  <div className="row gridclass">
          <div className="col-4" style={{paddingLeft: '0px' ,    marginLeft: '-5px'}}><Typography style={{fontSize:'18px'}}>
Occupation Type:</Typography></div>
          <div className="col-6"><Dropdown className='Dropdown-root4' options={occupation} onChange={(value)=>{setoccupation(value['value']); setoccupationerro(false)}}  value={occupationvalue} placeholder="Select an option" /></div>
          </div>   
          {/* {batcherror && <Typography style={{color:'red',textAlign:'left'}}>Batch Can't be blank!!!</Typography>} */}
       </div>
       {occupationerro && <Typography style={{color:'red',textAlign:'left'}}>Occupation Type Can't be blank!!!</Typography>}
          {/* <TextField
           type= 'file'
           
            id="profileimage"
            name="profileimage"
            label="Profile-image"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onBlur={(value) => setprofileimage(value.target.value)}
          /> */}
        
        </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     required
                     id="education"
                     name="Education"
                     label="Education"
                     value={Education}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setEducation(value.target.value)}
                   />
                   {educationerror && <Typography style={{color:'red',textAlign:'left'}}>Education Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     //required
                     id="linkdin_profile"
                     name="Linkdin_profile"
                     label="Linkdin Profile"
                     value={Linkdin}
                     fullWidthknow
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setLinkdin(value.target.value)}
                   />
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <TextField
                  
                     // required
                     id="native_place"
                     name="native_place"
                     label="Native Place"
                     value={NativePlace}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setNativePlace(value.target.value)}
                   />
                   {educationerror && <Typography style={{color:'red',textAlign:'left'}}>Education Can't be blank!!!</Typography>}
                 </Grid><Grid item xs={12} sm={6}>
                   <TextField
                  
                     //required
                     id="working_Place"
                     name="working_Place"
                     label="Working Place"
                     value={WorkingPlace}
                     fullWidth
                     autoComplete="given-name"
                     variant="standard"
                     onChange={(value)=>setWorkingPlace(value.target.value)}
                   />
                 </Grid>
                 <Grid item xs={12} sm={7}>
        <div className="input-group mb-3" >
  <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style={{borderBottomRightRadius: '0px', borderTopRightRadius: '0px'}}><Typography>Profile Image</Typography></span>
  </div>
  <input accept="image/*" type="file" className="form-control" style={{paddingTop: '4px',paddingLeft: '5px'}} placeholder="profileimage" id="profileimage" aria-label="Username" aria-describedby="basic-addon1"  onChange={(value) =>{ setprofileimage(value.target.files[0]); imagesize(value.target.files[0]); setimageload(true);}} />
       </div>
       {imagezie && <Typography style={{color:'red',textAlign:'left'}}>Image Size Shoudn't be morethen 5MB!!</Typography>}
      
        
        </Grid>
                 <Grid item xs={12} sm={12}>
                  <button className="btn btn-primary" style={{width: '230px',
             marginTop: '20px'}} onClick={submitform}><Typography>Update Form</Typography></button>
                <button className="btn btn-primary btn-pad"  style={{width: '230px',
             marginTop: '20px'}} onClick={cancel}><Typography>Cancel</Typography></button>
                 </Grid>
             </Grid> 
          </div>
              
             </div>
           </center>
        )}
  {/* <div className = "footer">Footer</div>
      <div className = "bot">
        <div style ={{display: showChat ? "" : "none"}}>
          <Form></Form>
        </div>       */}
        {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
        {/* <div> */}
          {/* {!showChat 
            ? <button className="btn5" onClick={() => startChat()}>click to chat... </button> 
            : <button className="btn5" onClick={() => hideChat()}>click to hide... </button>} */}
        {/* </div>
      </div>     */}
      </main>
      <div >
            <ThemeProvider theme={theme}>
                <ChatBot
 
                    // This appears as the header
                    // text for the chat bot
                    headerTitle="Alumnus Details"
                    steps={steps}
                    {...config}
 
                />
            </ThemeProvider>
        </div>
      {selectedAlumini != null && (
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ width: "100% !important" }}
        > 
          <DialogContent>
            <DialogContentText>
              <Typography className="textsize1">
                {" "}
                <strong className="textsize">Alumni Name:</strong>{" "}
                {maindata[selectedAlumini]["A_Name"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize">Mobile Number:</strong>{" "}
                {maindata[selectedAlumini]["A_Number"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize">Email:</strong>{" "}
                {maindata[selectedAlumini]["A_Email"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Occupation:</strong>{" "}
                {maindata[selectedAlumini]["A_Occupation"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Education:</strong>{" "}
                {maindata[selectedAlumini]["A_Education"]}
              </Typography>
            </DialogContentText>
            {/* <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Place:</strong>{" "}
                {maindata[selectedAlumini]["A_Place"]}
              </Typography>
            </DialogContentText> */}
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Batch:</strong>{" "}
                {maindata[selectedAlumini]["A_Batch"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Native Place:</strong>{" "}
                {maindata[selectedAlumini]["A_NativePlace"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Working Place:</strong>{" "}
                {maindata[selectedAlumini]["A_WorkingPlace"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> About Alumni:</strong>{" "}
                <a href="/moredetails">Click Here For More About</a>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
