import * as React from 'react';

import AppBar from "@material-ui/core/AppBar";
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
 import Menu from '@material-ui/core/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
//import logo from '../logo_1.png';
import Appbar from '../appbar';
import profile_im from "./user.png";
import servername from "../constants";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
// import AdbIcon from '@material-ui/core/Adb';
import '../App.css';
import { useTheme } from "../theme";
import { useState,useEffect } from 'react';
function Request() {
    const [currentTheme, setCurrentTheme] = useTheme();
    const [dalaavailable, setDalaAvailable] = useState(false);
    const [maindata, setdata] = useState([]);
    const [selectedAlumini, setSelectedAlumini] = useState(null);
     const [open, setOpen] = useState(false);
     useEffect(() => {
      //////console.log(servername);
      if (!localStorage.getItem('login_key')) {
        // user is not authenticated
        navigate('/login');
      }
      setOpen(true);
    }, [selectedAlumini]);
    const handleClickOpen = (index) => {

      setSelectedAlumini(index);
    };
    function requestdata() {
      const requestOptions1 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ key: "" }),
      };
      fetch(`${servername}/request_list`, requestOptions1)
        .then((response) => response.json())
        .then((data) => {
          //console.log("length", data);
          if(data.length == 0){
            setDalaAvailable(true);
            
          }else{
            setdata(data)
          }
          //////console.log("length", maindata.length);
        });
    }
    useEffect(() => {
      requestdata();
    }, []);
    const handleClose = () => {
      setOpen(false);
      setSelectedAlumini(null);
    };
   
 function acceptalumni(id){
  // alert(id);
  const requestOptions1 = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ verified: true }),
  };
  fetch(`${servername}/alumni_verified/${id}`, requestOptions1)
    .then((response) => response.json())
    .then((data) => {
      //console.log("length", data);
      requestdata();
      //////console.log("length", maindata.length);
    });
 }

 function deletealumni(id){
  var text = "Are you sure!!, You want to reject"
  if (confirm(text) == true) {
    const requestOptions1 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    fetch(`${servername}/deletealumni`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        //console.log("length", data);
        requestdata();
        //////console.log("length", maindata.length);
      });
  } else {
    return false;
  }
 
 }

return (
    <React.Fragment>
    <Appbar
     currentTheme={currentTheme}
     setCurrentTheme={setCurrentTheme}/>
   <main >
   {dalaavailable && <Typography className="message" style={{marginTop:'15%'}}>There is no Alumni Request</Typography>}
    {maindata.length > 0 && (   <div><Typography className="message" style={{marginTop:'2%'}}>Alumni Requests</Typography>  </div>)}
   <div className="row" style={{padding:30}}>
           
  
           {maindata.length > 0 && 
           
             maindata.map((element, elementindex) => (
              <div className="col-md-3 ">
                 <div className="cards" style={{maxHeight: '220px', minHeight: '180px'}}>
                   <div className="row" style={{paddingTop: '15px'}}>
                   <div className="col-3" style={{paddingTop: '10px'}}>
                     { element["A_profile"] ===undefined && <img className="profileim" src={profile_im} alt="Logo" style={{paddingTop: '5px'}} />}
                     {element["A_profile"] === '' && <img className="profileim" src={profile_im} alt="Logo" style={{paddingTop: '5px'}} />}
                     {element["A_profile"] !== '' && element["A_profile"]!==undefined &&  <img className="profileim" src={'https://jnvalumniassociation.co.in/public/images/'+element["A_profile"]} alt="Logo"  />}
                  
                     </div>
                     <div className="col-9"> 
                     <Typography className="name">
                     {element["A_Name"]}
                   </Typography>
                   <Typography>{element["A_Number"]}</Typography>
                   <Typography>{element["A_Batch"]}</Typography>
                   <div style={{marginTop: '10px',textAlign:'center'}}> <Button
                     variant="outlined"
                     color="primary"
                     onClick={() => handleClickOpen(elementindex)}
                     style={{backgroundColor: '#020248', color: 'white'}}
                   >
                     Know More
                   </Button>
                 
                   {(element["A_Email"] != localStorage.getItem('email') && localStorage.getItem('type') != 'admin') && 
                  <a href={`https://api.whatsapp.com/send/?phone=${element['A_Number']}&text=hi`}  target="_blank"><img  src={whatsapp} alt="Logo" style={{paddingTop: '0px', width:50}}/></a>  }
                   {/* <Button
                     variant="outlined"
                     color="primary"
                     onClick={() => handleClickOpen(elementindex)}
                     style={{backgroundColor: 'gray', color: 'white','marginLeft': '10px'}}
                   >
                   
                   
                   </Button> */}
                 
                   
                   </div>
                   <div style={{paddingTop:10}}>
                   <Button
                     variant="outlined"
                     color="primary"
                     onClick={() => acceptalumni(element["_id"])}
                     style={{backgroundColor: 'green', color: 'white','marginLeft': '10px', }}
                   >
                     Accept
                     {/* <FaArchive /> */}
                   </Button>
                   <Button
                     variant="outlined"
                     color="primary"
                     onClick={() => deletealumni(element["_id"])}
                     style={{backgroundColor: 'red', color: 'white','marginLeft': '10px'}}
                   >
                     Reject
                     {/* <FaArchive /> */}
                   </Button>
                   </div>
                   </div>
                </div>
                  
                 </div>
               </div>
            
             ))}
         </div>
   </main>
   {selectedAlumini != null && (
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ width: "100% !important" }}
        > 
          <DialogContent>
            <DialogContentText>
              <Typography className="textsize1">
                {" "}
                <strong className="textsize">Alumni Name:</strong>{" "}
                {maindata[selectedAlumini]["A_Name"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize">Mobile Number:</strong>{" "}
                {maindata[selectedAlumini]["A_Number"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize">Email:</strong>{" "}
                {maindata[selectedAlumini]["A_Email"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Occupation:</strong>{" "}
                {maindata[selectedAlumini]["A_Occupation"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Education:</strong>{" "}
                {maindata[selectedAlumini]["A_Education"]}
              </Typography>
            </DialogContentText>
            {/* <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Place:</strong>{" "}
                {maindata[selectedAlumini]["A_Place"]}
              </Typography>
            </DialogContentText> */}
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Batch:</strong>{" "}
                {maindata[selectedAlumini]["A_Batch"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Native Place:</strong>{" "}
                {maindata[selectedAlumini]["A_NativePlace"]}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Typography className="textsize1">
                <strong className="textsize"> Working Place:</strong>{" "}
                {maindata[selectedAlumini]["A_WorkingPlace"]}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
   </React.Fragment>
)
}
export default Request;
