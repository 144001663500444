import * as React from 'react';

import AppBar from "@material-ui/core/AppBar";
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
 import Menu from '@material-ui/core/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
//import logo from '../logo_1.png';
import Appbar from '../appbar';
import image from './college.jpg';
import heroim from './img2.png';
import servername from "../constants";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
// import AdbIcon from '@material-ui/core/Adb';
import '../App.css';
import { useTheme } from "../theme";
function Home() {
    const [currentTheme, setCurrentTheme] = useTheme();
return (
    <React.Fragment>
    <Appbar
     currentTheme={currentTheme}
     setCurrentTheme={setCurrentTheme}/>
   <main >
    <div className="Container "> <img src={heroim} alt="Alumni" className='imgclass' /></div>
    <div className="row aboutdiv">
        <div className="col-md-8 imclass" >
            <Typography><strong> About JNV Belagavi</strong></Typography>
            <Typography>Jawahar Navodaya Vidyalaya kothali is situated in Chikodi Taluk about 84 kms away from the District Head quarter. Falling in the district of ‘Belgaum’ or Belgaum the area experiences moderate climate. The abundance of Sugarcane fields in the district had earned the sobriquet as “Sakkare Zille”.
               The School campus flanks the foothills of shantigiri . The Vidyalaya spread its wings over 32 acres of land .It is a home away from home. </Typography>
          <Typography>
          The academic buildings including several classrooms.The widespread use of email and an intranet system shrinks the distances and keeps staff, students and administration in quick and easy contact with one another.
          </Typography>
          <Typography>Year of Establishment : 1987-88
          </Typography>
          <Typography> Extend of Land Available : 32.08 Acres</Typography>

        </div>
       
        <div className="col-md-4 imclass" > 
        <img src={image} className="imwidth" alt="Jnv" />
        </div>

    </div>
 <FloatingWhatsApp
        phoneNumber="9980135272"
        accountName="Admin"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={image}
      />
   </main>
   </React.Fragment>
)
}
export default Home;
