//import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from "../theme";
import Appbar from '../appbar';
import Box from '@material-ui/core/Box';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import React , { useState, useRef,useEffect } from "react";
import { FaBars, HiOutlineBarsArrowDown } from 'react-icons/fa';
import { useNavigate , Link } from "react-router-dom";
import servername from "../constants";

export default function ResetPassword() {
    let navigate = useNavigate ();
    const [currentTheme, setCurrentTheme] = useTheme();
    
    const [batch, setBatch] = useState('');
 
  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [Email, setEmail] = useState('');
  const [Occupation, setOccupation] = useState('');
  const [Passoutyear, setPassoutyear] = useState('');
  const[Linkdin,setLinkdin]=useState('');
  const[Education,setEducation]=useState('');
  const[Password,setPassword]=useState('');
  const[RePassword,setRePassword]=useState('');
  const [emailerror, setemailerror] = useState(false);
 
  const[loadopen, setloadopen] = useState(false);
  const[alertopen, setalertopen] = useState(false);
  const[erroralertopen, seterroralertopen] = useState(false);
  const[NativePlace,setNativePlace] = useState('');
  const[WorkingPlace,setWorkingPlace] = useState('');
  const[passworderror, setpassworderror] = useState(false);
  const[loginerror, setloginerror] = useState(false);
  const[emailexisterror, setemailexisterror] = useState(false);
  const[passwordmatcherror,setpasswordmatcherror]=useState(false);
  const[tokenerror,settokenerror]=useState(false);
  const[token,settoken]=useState('');

    const options = ['1987-1988','1988-1989','1989-1990','1991-1992','1992-1993','1993-1994','1994-1995','1995-1996','1996-1997','1997-1998','1998-1999','1999-2000','2000-2001','2001-2002','2002-2003','2003-2004','2004-2005','2005-2006','2006-2007','2007-2008','2008-2009','2009-2010','2010-2011','2011-2012','2012-2013','2013-2014','2014-2015','2015-2016','2016-2017','2017-2018','2018-2019','2019-2020','2020-2021','2021-2022','2022-2023','2023-2024','2024-2025','2025-2026','2026-2027','2028-2029','2029-2030',
      ];
      const defaultOption = 'Select';
   
      function submitform(){ 
        
        //////console.log('batch', batch);
       
       
        if(Email==''){
          setemailerror(true);
        }
        if(Password==''){
          setpassworderror(true);
        }if(token==''){
          settokenerror(true);
        }
       
       else{ 
        
        if( Email !='' &&  Password!='' ){
         
          //setloginerror(false);
         // setemailexisterror(false);
          setpasswordmatcherror(false);
          setloadopen(true);
          const requestOptions= {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token:token, password:Password})
        };
        fetch(`${servername}/forget-passwrd/reset_password/A_verified`, requestOptions)
            .then(response => response.json())
            .then((data)=>{ 
              //////console.log(data);
                if(data.ok == 1){
                    // localStorage.setItem('login_key', data.token);
                    // localStorage.setItem('email', data.email);
                    // localStorage.setItem('p_image', data.profile);
                    navigate('/login')
                }else if(data.ok == 0){
                    setloginerror(true);
                    setemailexisterror(true);
                    setloadopen(false);
                }else{
                  setloginerror(true);
                  setpasswordmatcherror(true);
                    setloadopen(false);
                }
              
         
           
            })
            .catch(error => {
              throw(error);
          });
           // //////console.log(Name);
         
        }
        
       else{
          return false;
        }
      }
      
     
      }


      function forgotpassword(){
        alert('ghhh')
      }

      const handleClickToOpen = () => {
        setalertopen(true);
      };
      
      const handleToClose = () => {
        setalertopen(false);
        seterroralertopen(false);
      }
      const handleToClose1 = () => {
       window.location.reload();
      }

  return (
    <React.Fragment>
        <Appbar
     currentTheme={currentTheme}
     setCurrentTheme={setCurrentTheme}/>
   
   <main className='mainclass'>
  <center>
  <div  style={{ backgroundColor: 'rgb(252 252 252)' , padding: '50px',borderRadius: '30px' }} className="MainDiv1"  >
 
  
 <div>
  <Dialog open={alertopen} onClose={handleToClose}>
        <DialogTitle style={{color:'green'}}>Success</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color:'black'}}>
           You Have Registered successfully 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToClose1} 
                  color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={erroralertopen} onClose={handleToClose}>
        <DialogTitle style={{color:'red'}}>Alert!!</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color:'black'}}>
           Alumni Name Already Exists
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToClose} 
                  color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

 {loadopen && <div className="loader1" ></div>  }

 <Grid container spacing={3}>
 <Grid item xs={12} sm={12}>
  <Typography style={{fontSize: '24px'}}>Reset Password</Typography>
  {/* <Typography style={{fontSize: '22px', marginTop: '10px'}}>Login</Typography> */}
 
  {loginerror && <Typography style={{color:'red',textAlign:'center'}}>  Login Failed!!</Typography>}

 </Grid>
       
        <Grid item xs={12} sm={12}>
          <TextField
         
            required
            type='password'
            id="password"
            name="password"
            label="password"
            fullWidth
            autoComplete='new-password'
            // autoComplete="given-name"
            variant="standard"
            onBlur={(value)=>{setEmail(value.target.value); setemailerror(false)}}
          />
          
          {emailerror && <Typography style={{color:'red',textAlign:'left'}}>Password Can't be blank!!!</Typography>}
        </Grid>
      
        <Grid item xs={12} sm={12}>
          <TextField
         
            required
            type='password'
            id="rePassword"
            name="rePassword"
            label="RePassword"
            fullWidth
            autoComplete='new-password'
            // autoComplete="given-name"
            variant="standard"
            onBlur={(value)=>{setPassword(value.target.value); setpassworderror(false);}}
          />
           {passwordmatcherror && <Typography style={{color:'red',textAlign:'left'}}>Invalid password!!!</Typography>}
           {passworderror && <Typography style={{color:'red',textAlign:'left'}}>RePassword Can't be blank!!!</Typography>}
        </Grid>
        {passwordmatcherror && <Typography style={{color:'red',textAlign:'left'}}>Invalid password!!!</Typography>}
           {/* {passworderror && <Typography style={{color:'red',textAlign:'left'}}>Password Can't be blank!!!</Typography>} */}
        <Grid item xs={12} sm={12}>
          <TextField
         
            required
            type='text'
            id="token"
            name="token"
            label="Token"
            fullWidth
            autoComplete='new-password'
            // autoComplete="given-name"
            variant="standard"
            onBlur={(value)=>{settoken(value.target.value); settokenerror(false);}}
          />
          <Typography style={{color:'blue',textAlign:'left'}}>Get Token From Your Registerd Email</Typography>
            {tokenerror && <Typography style={{color:'red',textAlign:'left'}}>Token Can't be blank!!!</Typography>}
        </Grid>
        {/* <Grid item xs={12} sm={12}>
        <Typography onClick={forgotpassword}>Forgot Password</Typography>
        </Grid> */}
        <Grid item xs={12} sm={12}>
         <button className="btn btn-primary" style={{width: '230px',
    marginTop: '20px'}} onClick={submitform}><Typography>Update</Typography></button>
        </Grid>
      
    </Grid> 
 </div>
     
    </div>
  </center>





  
    

   </main>
     
    </React.Fragment>
  );
}