// import { useTheme } from "@mui/material";
// import { ResponsiveBar } from "@nivo/bar";
// import { tokens } from "../theme";
// import { mockBarData as data } from "../data/mockData";

// const BarChart = ({ data }) => {

//   console.log(data)
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   return (
//     <ResponsiveBar
//       data={data}
//       theme={{
//         axis: {
//           domain: {
//             line: {
//               stroke: colors.grey[100],
//             },
//           },
//           legend: {
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//           ticks: {
//             line: {
//               stroke: colors.grey[100],
//               strokeWidth: 1,
//             },
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//         },
//         legends: {
//           text: {
//             fill: colors.grey[100],
//           },
//         },
//       }}
//       // keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
//       // indexBy="country"
//       // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
//       // padding={0.3}
//       // valueScale={{ type: "linear" }}
//       // indexScale={{ type: "band", round: true }}
//       // colors={{ scheme: "nivo" }}
//       // defs={[
//       //   {
//       //     id: "dots",
//       //     type: "patternDots",
//       //     background: "inherit",
//       //     color: "#38bcb2",
//       //     size: 4,
//       //     padding: 1,
//       //     stagger: true,
//       //   },
//       //   {
//       //     id: "lines",
//       //     type: "patternLines",
//       //     background: "inherit",
//       //     color: "#eed312",
//       //     rotation: -45,
//       //     lineWidth: 6,
//       //     spacing: 10,
//       //   },
//       // ]}
//       // borderColor={{
//       //   from: "color",
//       //   modifiers: [["darker", "1.6"]],
//       // }}
//       // axisTop={null}
//       // axisRight={null}
//       // axisBottom={{
//       //   tickSize: 5,
//       //   tickPadding: 5,
//       //   tickRotation: 0,
//       //   legend: isDashboard ? undefined : "country",
//       //   legendPosition: "middle",
//       //   legendOffset: 32,
//       // }}
//       // axisLeft={{
//       //   tickSize: 5,
//       //   tickPadding: 5,
//       //   tickRotation: 0,
//       //   legend: isDashboard ? undefined : "food",
//       //   legendPosition: "middle",
//       //   legendOffset: -40,
//       // }}
//       // enableLabel={false}
//       // labelSkipWidth={12}
//       // labelSkipHeight={12}
//       // labelTextColor={{
//       //   from: "color",
//       //   modifiers: [["darker", 1.6]],
//       // }}
//       // legends={[
//       //   {
//       //     dataFrom: "keys",
//       //     anchor: "bottom-right",
//       //     direction: "column",
//       //     justify: false,
//       //     translateX: 120,
//       //     translateY: 0,
//       //     itemsSpacing: 2,
//       //     itemWidth: 100,
//       //     itemHeight: 20,
//       //     itemDirection: "left-to-right",
//       //     itemOpacity: 0.85,
//       //     symbolSize: 20,
//       //     effects: [
//       //       {
//       //         on: "hover",
//       //         style: {
//       //           itemOpacity: 1,
//       //         },
//       //       },
//       //     ],
//       //   },
//       // ]}
//       // role="application"
//       // barAriaLabel={function (e) {
//       //   return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
//       // }}
//     />
//   );
// };

// export default BarChart;



import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import Modal from './PopupModal';

const BarChart = ({ data }) => {
  const theme = useTheme();

  const alumniPerBatch = {};
  data.forEach((alumnus) => {
    const { A_Batch } = alumnus;
    if (!alumniPerBatch[A_Batch]) {
      alumniPerBatch[A_Batch] = [];
    }
    alumniPerBatch[A_Batch].push(alumnus);
  });

  const alumniCountPerBatch = Object.entries(alumniPerBatch).map(([batch, alumni]) => ({
    batch,
    count: alumni.length,
    alumniDetails: alumni, // Array of alumni details for each batch
  }));

  const [selectedBatchData, setSelectedBatchData] = useState(null);

  const handleBarClick = (event, barData) => {
    console.log('Bar Click Event:', event);
    console.log('Bar Click Data:', barData);

    const batch = event.data.batch;
    const alumniDetails = event.data.alumniDetails;

    setSelectedBatchData(prevData => {
      console.log('Previous Data:', prevData); // Log the previous state before the update
      return { batch, alumniDetails }; // Return the updated state
    });
  };



  const handleCloseModal = () => {
    setSelectedBatchData(null);
  };

  const customColors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
    '#aec7e8',
    '#ffbb78',
    '#98df8a',
    '#ff9896',
    '#c5b0d5',
    '#c49c94',
    '#f7b6d2',
    '#c7c7c7',
    '#dbdb8d',
    '#9edae5',
  ];
  return (
    <>
      <ResponsiveBar

        onClick={handleBarClick}
        data={alumniCountPerBatch}
        keys={['count']}
        indexBy="batch"
        margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'symlog' }}
        indexScale={{ type: 'band', round: true }}
        colors={customColors}
        axisBottom={{
          tickSize: 5,
          tickPadding: 2,
          tickRotation: -40,
          legend: 'Batch',
          legendPosition: 'middle',
          legendOffset: 50,
          style: {
            legend: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Alumni Count',
          legendPosition: 'middle',
          legendOffset: -40,
          label: 'Alumni Count',
          style: {
            legend: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },

        }}
        // tooltip={({ data }) => (
        //   <div style={{ background: 'white', padding: '10px' }}>
        //     <strong>{`Batch: ${data.indexValue}, Count: ${data.value}`}</strong>
        //     <p>Click to view data</p>
        //   </div>
        // )}
        
        defs={[
          {
            id: 'gradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 0.3 },
            ],
          },
        ]}
        fill={[
          {
            match: {
              id: 'count',
            },
            id: 'gradient',
          },
        ]}
        role="application"
        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} alumni count in batch: ${e.indexValue}`}


      />
      {selectedBatchData && (
        <Modal message={'Alumni Details For Batch'} data={selectedBatchData} onClose={handleCloseModal} />
      )}


    </>
  );
};

export default BarChart;

